/*
Theme Name: Cree - Light A Better Way
Author: Rivers Agency // Trevor Krumm
Author URI: http://riversagency.com/
Version: 1.0
*/
/* CSS Section Imports
----------------------------------------------- */
/*-----------------------------------------------
  Base SCSS - No styles actually output.
  Variables / Functions / Mixins
----------------------------------------------- */
/*-----------------------------------------------
  Base SCSS - No styles actually output. Variables / Functions / Mixins
----------------------------------------------- */
/*-----------------------------------------------
  Framework SCSS
  Resets / Bootstrap / Typography / Functional CSS
----------------------------------------------- */
/*-----------------------------------------------
  Framework SCSS - Resets / Bootstrap / Typography / Functional CSS
----------------------------------------------- */
/* CSS Document */
/* http://meyerweb.com/eric/tools/css/reset/ 

   v2.0 | 20110126

   License: none (public domain)
   
   Meyers Reset CSS

*/
/* --- RESET CSS --- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* --- END RESET --- */
strong {
  font-weight: bold; }

em {
  font-style: italic; }

a {
  cursor: pointer; }

sup {
  font-size: .7em;
  vertical-align: top; }

body {
  font-size: 10px;
  font-family: 'Open Sans', sans-serif; }

html,
body {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  height: 100%;
  position: relative; }

table * {
  vertical-align: top; }

/*-----------------------------------------------
  Bootstrap Less
----------------------------------------------- */
/*
//  Over ride Bootstrap Core Variables in ../bootstrap/stylesheets/bootstrap/__custom-variables.scss
// to recalculate grid system appropriately ***
----------------------------------------------- */
/*!
 * Bootstrap v3.3.5 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important; }
  .label {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("css/bootstrap/fonts/bootstrap/glyphicons-halflings-regular.eot");
  src: url("css/bootstrap/fonts/bootstrap/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("css/bootstrap/fonts/bootstrap/glyphicons-halflings-regular.woff2") format("woff2"), url("css/bootstrap/fonts/bootstrap/glyphicons-halflings-regular.woff") format("woff"), url("css/bootstrap/fonts/bootstrap/glyphicons-halflings-regular.ttf") format("truetype"), url("css/bootstrap/fonts/bootstrap/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg"); }

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.glyphicon-asterisk:before {
  content: "\2a"; }

.glyphicon-plus:before {
  content: "\2b"; }

.glyphicon-euro:before,
.glyphicon-eur:before {
  content: "\20ac"; }

.glyphicon-minus:before {
  content: "\2212"; }

.glyphicon-cloud:before {
  content: "\2601"; }

.glyphicon-envelope:before {
  content: "\2709"; }

.glyphicon-pencil:before {
  content: "\270f"; }

.glyphicon-glass:before {
  content: "\e001"; }

.glyphicon-music:before {
  content: "\e002"; }

.glyphicon-search:before {
  content: "\e003"; }

.glyphicon-heart:before {
  content: "\e005"; }

.glyphicon-star:before {
  content: "\e006"; }

.glyphicon-star-empty:before {
  content: "\e007"; }

.glyphicon-user:before {
  content: "\e008"; }

.glyphicon-film:before {
  content: "\e009"; }

.glyphicon-th-large:before {
  content: "\e010"; }

.glyphicon-th:before {
  content: "\e011"; }

.glyphicon-th-list:before {
  content: "\e012"; }

.glyphicon-ok:before {
  content: "\e013"; }

.glyphicon-remove:before {
  content: "\e014"; }

.glyphicon-zoom-in:before {
  content: "\e015"; }

.glyphicon-zoom-out:before {
  content: "\e016"; }

.glyphicon-off:before {
  content: "\e017"; }

.glyphicon-signal:before {
  content: "\e018"; }

.glyphicon-cog:before {
  content: "\e019"; }

.glyphicon-trash:before {
  content: "\e020"; }

.glyphicon-home:before {
  content: "\e021"; }

.glyphicon-file:before {
  content: "\e022"; }

.glyphicon-time:before {
  content: "\e023"; }

.glyphicon-road:before {
  content: "\e024"; }

.glyphicon-download-alt:before {
  content: "\e025"; }

.glyphicon-download:before {
  content: "\e026"; }

.glyphicon-upload:before {
  content: "\e027"; }

.glyphicon-inbox:before {
  content: "\e028"; }

.glyphicon-play-circle:before {
  content: "\e029"; }

.glyphicon-repeat:before {
  content: "\e030"; }

.glyphicon-refresh:before {
  content: "\e031"; }

.glyphicon-list-alt:before {
  content: "\e032"; }

.glyphicon-lock:before {
  content: "\e033"; }

.glyphicon-flag:before {
  content: "\e034"; }

.glyphicon-headphones:before {
  content: "\e035"; }

.glyphicon-volume-off:before {
  content: "\e036"; }

.glyphicon-volume-down:before {
  content: "\e037"; }

.glyphicon-volume-up:before {
  content: "\e038"; }

.glyphicon-qrcode:before {
  content: "\e039"; }

.glyphicon-barcode:before {
  content: "\e040"; }

.glyphicon-tag:before {
  content: "\e041"; }

.glyphicon-tags:before {
  content: "\e042"; }

.glyphicon-book:before {
  content: "\e043"; }

.glyphicon-bookmark:before {
  content: "\e044"; }

.glyphicon-print:before {
  content: "\e045"; }

.glyphicon-camera:before {
  content: "\e046"; }

.glyphicon-font:before {
  content: "\e047"; }

.glyphicon-bold:before {
  content: "\e048"; }

.glyphicon-italic:before {
  content: "\e049"; }

.glyphicon-text-height:before {
  content: "\e050"; }

.glyphicon-text-width:before {
  content: "\e051"; }

.glyphicon-align-left:before {
  content: "\e052"; }

.glyphicon-align-center:before {
  content: "\e053"; }

.glyphicon-align-right:before {
  content: "\e054"; }

.glyphicon-align-justify:before {
  content: "\e055"; }

.glyphicon-list:before {
  content: "\e056"; }

.glyphicon-indent-left:before {
  content: "\e057"; }

.glyphicon-indent-right:before {
  content: "\e058"; }

.glyphicon-facetime-video:before {
  content: "\e059"; }

.glyphicon-picture:before {
  content: "\e060"; }

.glyphicon-map-marker:before {
  content: "\e062"; }

.glyphicon-adjust:before {
  content: "\e063"; }

.glyphicon-tint:before {
  content: "\e064"; }

.glyphicon-edit:before {
  content: "\e065"; }

.glyphicon-share:before {
  content: "\e066"; }

.glyphicon-check:before {
  content: "\e067"; }

.glyphicon-move:before {
  content: "\e068"; }

.glyphicon-step-backward:before {
  content: "\e069"; }

.glyphicon-fast-backward:before {
  content: "\e070"; }

.glyphicon-backward:before {
  content: "\e071"; }

.glyphicon-play:before {
  content: "\e072"; }

.glyphicon-pause:before {
  content: "\e073"; }

.glyphicon-stop:before {
  content: "\e074"; }

.glyphicon-forward:before {
  content: "\e075"; }

.glyphicon-fast-forward:before {
  content: "\e076"; }

.glyphicon-step-forward:before {
  content: "\e077"; }

.glyphicon-eject:before {
  content: "\e078"; }

.glyphicon-chevron-left:before {
  content: "\e079"; }

.glyphicon-chevron-right:before {
  content: "\e080"; }

.glyphicon-plus-sign:before {
  content: "\e081"; }

.glyphicon-minus-sign:before {
  content: "\e082"; }

.glyphicon-remove-sign:before {
  content: "\e083"; }

.glyphicon-ok-sign:before {
  content: "\e084"; }

.glyphicon-question-sign:before {
  content: "\e085"; }

.glyphicon-info-sign:before {
  content: "\e086"; }

.glyphicon-screenshot:before {
  content: "\e087"; }

.glyphicon-remove-circle:before {
  content: "\e088"; }

.glyphicon-ok-circle:before {
  content: "\e089"; }

.glyphicon-ban-circle:before {
  content: "\e090"; }

.glyphicon-arrow-left:before {
  content: "\e091"; }

.glyphicon-arrow-right:before {
  content: "\e092"; }

.glyphicon-arrow-up:before {
  content: "\e093"; }

.glyphicon-arrow-down:before {
  content: "\e094"; }

.glyphicon-share-alt:before {
  content: "\e095"; }

.glyphicon-resize-full:before {
  content: "\e096"; }

.glyphicon-resize-small:before {
  content: "\e097"; }

.glyphicon-exclamation-sign:before {
  content: "\e101"; }

.glyphicon-gift:before {
  content: "\e102"; }

.glyphicon-leaf:before {
  content: "\e103"; }

.glyphicon-fire:before {
  content: "\e104"; }

.glyphicon-eye-open:before {
  content: "\e105"; }

.glyphicon-eye-close:before {
  content: "\e106"; }

.glyphicon-warning-sign:before {
  content: "\e107"; }

.glyphicon-plane:before {
  content: "\e108"; }

.glyphicon-calendar:before {
  content: "\e109"; }

.glyphicon-random:before {
  content: "\e110"; }

.glyphicon-comment:before {
  content: "\e111"; }

.glyphicon-magnet:before {
  content: "\e112"; }

.glyphicon-chevron-up:before {
  content: "\e113"; }

.glyphicon-chevron-down:before {
  content: "\e114"; }

.glyphicon-retweet:before {
  content: "\e115"; }

.glyphicon-shopping-cart:before {
  content: "\e116"; }

.glyphicon-folder-close:before {
  content: "\e117"; }

.glyphicon-folder-open:before {
  content: "\e118"; }

.glyphicon-resize-vertical:before {
  content: "\e119"; }

.glyphicon-resize-horizontal:before {
  content: "\e120"; }

.glyphicon-hdd:before {
  content: "\e121"; }

.glyphicon-bullhorn:before {
  content: "\e122"; }

.glyphicon-bell:before {
  content: "\e123"; }

.glyphicon-certificate:before {
  content: "\e124"; }

.glyphicon-thumbs-up:before {
  content: "\e125"; }

.glyphicon-thumbs-down:before {
  content: "\e126"; }

.glyphicon-hand-right:before {
  content: "\e127"; }

.glyphicon-hand-left:before {
  content: "\e128"; }

.glyphicon-hand-up:before {
  content: "\e129"; }

.glyphicon-hand-down:before {
  content: "\e130"; }

.glyphicon-circle-arrow-right:before {
  content: "\e131"; }

.glyphicon-circle-arrow-left:before {
  content: "\e132"; }

.glyphicon-circle-arrow-up:before {
  content: "\e133"; }

.glyphicon-circle-arrow-down:before {
  content: "\e134"; }

.glyphicon-globe:before {
  content: "\e135"; }

.glyphicon-wrench:before {
  content: "\e136"; }

.glyphicon-tasks:before {
  content: "\e137"; }

.glyphicon-filter:before {
  content: "\e138"; }

.glyphicon-briefcase:before {
  content: "\e139"; }

.glyphicon-fullscreen:before {
  content: "\e140"; }

.glyphicon-dashboard:before {
  content: "\e141"; }

.glyphicon-paperclip:before {
  content: "\e142"; }

.glyphicon-heart-empty:before {
  content: "\e143"; }

.glyphicon-link:before {
  content: "\e144"; }

.glyphicon-phone:before {
  content: "\e145"; }

.glyphicon-pushpin:before {
  content: "\e146"; }

.glyphicon-usd:before {
  content: "\e148"; }

.glyphicon-gbp:before {
  content: "\e149"; }

.glyphicon-sort:before {
  content: "\e150"; }

.glyphicon-sort-by-alphabet:before {
  content: "\e151"; }

.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152"; }

.glyphicon-sort-by-order:before {
  content: "\e153"; }

.glyphicon-sort-by-order-alt:before {
  content: "\e154"; }

.glyphicon-sort-by-attributes:before {
  content: "\e155"; }

.glyphicon-sort-by-attributes-alt:before {
  content: "\e156"; }

.glyphicon-unchecked:before {
  content: "\e157"; }

.glyphicon-expand:before {
  content: "\e158"; }

.glyphicon-collapse-down:before {
  content: "\e159"; }

.glyphicon-collapse-up:before {
  content: "\e160"; }

.glyphicon-log-in:before {
  content: "\e161"; }

.glyphicon-flash:before {
  content: "\e162"; }

.glyphicon-log-out:before {
  content: "\e163"; }

.glyphicon-new-window:before {
  content: "\e164"; }

.glyphicon-record:before {
  content: "\e165"; }

.glyphicon-save:before {
  content: "\e166"; }

.glyphicon-open:before {
  content: "\e167"; }

.glyphicon-saved:before {
  content: "\e168"; }

.glyphicon-import:before {
  content: "\e169"; }

.glyphicon-export:before {
  content: "\e170"; }

.glyphicon-send:before {
  content: "\e171"; }

.glyphicon-floppy-disk:before {
  content: "\e172"; }

.glyphicon-floppy-saved:before {
  content: "\e173"; }

.glyphicon-floppy-remove:before {
  content: "\e174"; }

.glyphicon-floppy-save:before {
  content: "\e175"; }

.glyphicon-floppy-open:before {
  content: "\e176"; }

.glyphicon-credit-card:before {
  content: "\e177"; }

.glyphicon-transfer:before {
  content: "\e178"; }

.glyphicon-cutlery:before {
  content: "\e179"; }

.glyphicon-header:before {
  content: "\e180"; }

.glyphicon-compressed:before {
  content: "\e181"; }

.glyphicon-earphone:before {
  content: "\e182"; }

.glyphicon-phone-alt:before {
  content: "\e183"; }

.glyphicon-tower:before {
  content: "\e184"; }

.glyphicon-stats:before {
  content: "\e185"; }

.glyphicon-sd-video:before {
  content: "\e186"; }

.glyphicon-hd-video:before {
  content: "\e187"; }

.glyphicon-subtitles:before {
  content: "\e188"; }

.glyphicon-sound-stereo:before {
  content: "\e189"; }

.glyphicon-sound-dolby:before {
  content: "\e190"; }

.glyphicon-sound-5-1:before {
  content: "\e191"; }

.glyphicon-sound-6-1:before {
  content: "\e192"; }

.glyphicon-sound-7-1:before {
  content: "\e193"; }

.glyphicon-copyright-mark:before {
  content: "\e194"; }

.glyphicon-registration-mark:before {
  content: "\e195"; }

.glyphicon-cloud-download:before {
  content: "\e197"; }

.glyphicon-cloud-upload:before {
  content: "\e198"; }

.glyphicon-tree-conifer:before {
  content: "\e199"; }

.glyphicon-tree-deciduous:before {
  content: "\e200"; }

.glyphicon-cd:before {
  content: "\e201"; }

.glyphicon-save-file:before {
  content: "\e202"; }

.glyphicon-open-file:before {
  content: "\e203"; }

.glyphicon-level-up:before {
  content: "\e204"; }

.glyphicon-copy:before {
  content: "\e205"; }

.glyphicon-paste:before {
  content: "\e206"; }

.glyphicon-alert:before {
  content: "\e209"; }

.glyphicon-equalizer:before {
  content: "\e210"; }

.glyphicon-king:before {
  content: "\e211"; }

.glyphicon-queen:before {
  content: "\e212"; }

.glyphicon-pawn:before {
  content: "\e213"; }

.glyphicon-bishop:before {
  content: "\e214"; }

.glyphicon-knight:before {
  content: "\e215"; }

.glyphicon-baby-formula:before {
  content: "\e216"; }

.glyphicon-tent:before {
  content: "\26fa"; }

.glyphicon-blackboard:before {
  content: "\e218"; }

.glyphicon-bed:before {
  content: "\e219"; }

.glyphicon-apple:before {
  content: "\f8ff"; }

.glyphicon-erase:before {
  content: "\e221"; }

.glyphicon-hourglass:before {
  content: "\231b"; }

.glyphicon-lamp:before {
  content: "\e223"; }

.glyphicon-duplicate:before {
  content: "\e224"; }

.glyphicon-piggy-bank:before {
  content: "\e225"; }

.glyphicon-scissors:before {
  content: "\e226"; }

.glyphicon-bitcoin:before {
  content: "\e227"; }

.glyphicon-btc:before {
  content: "\e227"; }

.glyphicon-xbt:before {
  content: "\e227"; }

.glyphicon-yen:before {
  content: "\00a5"; }

.glyphicon-jpy:before {
  content: "\00a5"; }

.glyphicon-ruble:before {
  content: "\20bd"; }

.glyphicon-rub:before {
  content: "\20bd"; }

.glyphicon-scale:before {
  content: "\e230"; }

.glyphicon-ice-lolly:before {
  content: "\e231"; }

.glyphicon-ice-lolly-tasted:before {
  content: "\e232"; }

.glyphicon-education:before {
  content: "\e233"; }

.glyphicon-option-horizontal:before {
  content: "\e234"; }

.glyphicon-option-vertical:before {
  content: "\e235"; }

.glyphicon-menu-hamburger:before {
  content: "\e236"; }

.glyphicon-modal-window:before {
  content: "\e237"; }

.glyphicon-oil:before {
  content: "\e238"; }

.glyphicon-grain:before {
  content: "\e239"; }

.glyphicon-sunglasses:before {
  content: "\e240"; }

.glyphicon-text-size:before {
  content: "\e241"; }

.glyphicon-text-color:before {
  content: "\e242"; }

.glyphicon-text-background:before {
  content: "\e243"; }

.glyphicon-object-align-top:before {
  content: "\e244"; }

.glyphicon-object-align-bottom:before {
  content: "\e245"; }

.glyphicon-object-align-horizontal:before {
  content: "\e246"; }

.glyphicon-object-align-left:before {
  content: "\e247"; }

.glyphicon-object-align-vertical:before {
  content: "\e248"; }

.glyphicon-object-align-right:before {
  content: "\e249"; }

.glyphicon-triangle-right:before {
  content: "\e250"; }

.glyphicon-triangle-left:before {
  content: "\e251"; }

.glyphicon-triangle-bottom:before {
  content: "\e252"; }

.glyphicon-triangle-top:before {
  content: "\e253"; }

.glyphicon-console:before {
  content: "\e254"; }

.glyphicon-superscript:before {
  content: "\e255"; }

.glyphicon-subscript:before {
  content: "\e256"; }

.glyphicon-menu-left:before {
  content: "\e257"; }

.glyphicon-menu-right:before {
  content: "\e258"; }

.glyphicon-menu-down:before {
  content: "\e259"; }

.glyphicon-menu-up:before {
  content: "\e260"; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-size: 10px;
  -webkit-tap-highlight-color: transparent; }

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  color: #333333;
  background-color: #fff; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

a {
  color: #337ab7;
  text-decoration: none; }
  a:hover, a:focus {
    color: #23527c;
    text-decoration: underline; }
  a:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }

figure {
  margin: 0; }

img {
  vertical-align: middle; }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.img-rounded {
  border-radius: 6px; }

.img-thumbnail {
  padding: 4px;
  line-height: 1.42857;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto; }

.img-circle {
  border-radius: 50%; }

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

[role="button"] {
  cursor: pointer; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px; }
  .container:before, .container:after {
    content: " ";
    display: table; }
  .container:after {
    clear: both; }
  @media (min-width: 828px) {
    .container {
      width: 740px; } }
  @media (min-width: 980px) {
    .container {
      width: 892px; } }
  @media (min-width: 1250px) {
    .container {
      width: 1162px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px; }
  .container-fluid:before, .container-fluid:after {
    content: " ";
    display: table; }
  .container-fluid:after {
    clear: both; }

.row {
  margin-left: -16px;
  margin-right: -16px; }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 16px;
  padding-right: 16px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-1 {
  width: 8.33333%; }

.col-xs-2 {
  width: 16.66667%; }

.col-xs-3 {
  width: 25%; }

.col-xs-4 {
  width: 33.33333%; }

.col-xs-5 {
  width: 41.66667%; }

.col-xs-6 {
  width: 50%; }

.col-xs-7 {
  width: 58.33333%; }

.col-xs-8 {
  width: 66.66667%; }

.col-xs-9 {
  width: 75%; }

.col-xs-10 {
  width: 83.33333%; }

.col-xs-11 {
  width: 91.66667%; }

.col-xs-12 {
  width: 100%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-pull-1 {
  right: 8.33333%; }

.col-xs-pull-2 {
  right: 16.66667%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-4 {
  right: 33.33333%; }

.col-xs-pull-5 {
  right: 41.66667%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-7 {
  right: 58.33333%; }

.col-xs-pull-8 {
  right: 66.66667%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-10 {
  right: 83.33333%; }

.col-xs-pull-11 {
  right: 91.66667%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-push-1 {
  left: 8.33333%; }

.col-xs-push-2 {
  left: 16.66667%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-4 {
  left: 33.33333%; }

.col-xs-push-5 {
  left: 41.66667%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-7 {
  left: 58.33333%; }

.col-xs-push-8 {
  left: 66.66667%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-10 {
  left: 83.33333%; }

.col-xs-push-11 {
  left: 91.66667%; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-offset-0 {
  margin-left: 0%; }

.col-xs-offset-1 {
  margin-left: 8.33333%; }

.col-xs-offset-2 {
  margin-left: 16.66667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.33333%; }

.col-xs-offset-5 {
  margin-left: 41.66667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.33333%; }

.col-xs-offset-8 {
  margin-left: 66.66667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.33333%; }

.col-xs-offset-11 {
  margin-left: 91.66667%; }

.col-xs-offset-12 {
  margin-left: 100%; }

@media (min-width: 828px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-1 {
    width: 8.33333%; }
  .col-sm-2 {
    width: 16.66667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.33333%; }
  .col-sm-5 {
    width: 41.66667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.33333%; }
  .col-sm-8 {
    width: 66.66667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.33333%; }
  .col-sm-11 {
    width: 91.66667%; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-pull-1 {
    right: 8.33333%; }
  .col-sm-pull-2 {
    right: 16.66667%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-4 {
    right: 33.33333%; }
  .col-sm-pull-5 {
    right: 41.66667%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-7 {
    right: 58.33333%; }
  .col-sm-pull-8 {
    right: 66.66667%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-10 {
    right: 83.33333%; }
  .col-sm-pull-11 {
    right: 91.66667%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-push-1 {
    left: 8.33333%; }
  .col-sm-push-2 {
    left: 16.66667%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-4 {
    left: 33.33333%; }
  .col-sm-push-5 {
    left: 41.66667%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-7 {
    left: 58.33333%; }
  .col-sm-push-8 {
    left: 66.66667%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-10 {
    left: 83.33333%; }
  .col-sm-push-11 {
    left: 91.66667%; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-offset-0 {
    margin-left: 0%; }
  .col-sm-offset-1 {
    margin-left: 8.33333%; }
  .col-sm-offset-2 {
    margin-left: 16.66667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.33333%; }
  .col-sm-offset-5 {
    margin-left: 41.66667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.33333%; }
  .col-sm-offset-8 {
    margin-left: 66.66667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.33333%; }
  .col-sm-offset-11 {
    margin-left: 91.66667%; }
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 980px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-1 {
    width: 8.33333%; }
  .col-md-2 {
    width: 16.66667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.33333%; }
  .col-md-5 {
    width: 41.66667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.33333%; }
  .col-md-8 {
    width: 66.66667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.33333%; }
  .col-md-11 {
    width: 91.66667%; }
  .col-md-12 {
    width: 100%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-pull-1 {
    right: 8.33333%; }
  .col-md-pull-2 {
    right: 16.66667%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-4 {
    right: 33.33333%; }
  .col-md-pull-5 {
    right: 41.66667%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-7 {
    right: 58.33333%; }
  .col-md-pull-8 {
    right: 66.66667%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-10 {
    right: 83.33333%; }
  .col-md-pull-11 {
    right: 91.66667%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-push-1 {
    left: 8.33333%; }
  .col-md-push-2 {
    left: 16.66667%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-4 {
    left: 33.33333%; }
  .col-md-push-5 {
    left: 41.66667%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-7 {
    left: 58.33333%; }
  .col-md-push-8 {
    left: 66.66667%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-10 {
    left: 83.33333%; }
  .col-md-push-11 {
    left: 91.66667%; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .col-md-offset-1 {
    margin-left: 8.33333%; }
  .col-md-offset-2 {
    margin-left: 16.66667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.33333%; }
  .col-md-offset-5 {
    margin-left: 41.66667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.33333%; }
  .col-md-offset-8 {
    margin-left: 66.66667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.33333%; }
  .col-md-offset-11 {
    margin-left: 91.66667%; }
  .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1250px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-1 {
    width: 8.33333%; }
  .col-lg-2 {
    width: 16.66667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.33333%; }
  .col-lg-5 {
    width: 41.66667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.33333%; }
  .col-lg-8 {
    width: 66.66667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.33333%; }
  .col-lg-11 {
    width: 91.66667%; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-pull-1 {
    right: 8.33333%; }
  .col-lg-pull-2 {
    right: 16.66667%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-4 {
    right: 33.33333%; }
  .col-lg-pull-5 {
    right: 41.66667%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-7 {
    right: 58.33333%; }
  .col-lg-pull-8 {
    right: 66.66667%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-10 {
    right: 83.33333%; }
  .col-lg-pull-11 {
    right: 91.66667%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-push-1 {
    left: 8.33333%; }
  .col-lg-push-2 {
    left: 16.66667%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-4 {
    left: 33.33333%; }
  .col-lg-push-5 {
    left: 41.66667%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-7 {
    left: 58.33333%; }
  .col-lg-push-8 {
    left: 66.66667%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-10 {
    left: 83.33333%; }
  .col-lg-push-11 {
    left: 91.66667%; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-offset-0 {
    margin-left: 0%; }
  .col-lg-offset-1 {
    margin-left: 8.33333%; }
  .col-lg-offset-2 {
    margin-left: 16.66667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.33333%; }
  .col-lg-offset-5 {
    margin-left: 41.66667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.33333%; }
  .col-lg-offset-8 {
    margin-left: 66.66667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.33333%; }
  .col-lg-offset-11 {
    margin-left: 91.66667%; }
  .col-lg-offset-12 {
    margin-left: 100%; } }

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0; }

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5; }

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold; }

input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

input[type="file"] {
  display: block; }

input[type="range"] {
  display: block;
  width: 100%; }

select[multiple],
select[size] {
  height: auto; }

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555; }

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .form-control::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #999; }
  .form-control::-webkit-input-placeholder {
    color: #999; }
  .form-control[disabled], .form-control[readonly],
  fieldset[disabled] .form-control {
    background-color: #eeeeee;
    opacity: 1; }
  .form-control[disabled],
  fieldset[disabled] .form-control {
    cursor: not-allowed; }

textarea.form-control {
  height: auto; }

input[type="search"] {
  -webkit-appearance: none; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 34px; }
  input[type="date"].input-sm,
  .input-group-sm input[type="date"],
  input[type="time"].input-sm,
  .input-group-sm
  input[type="time"],
  input[type="datetime-local"].input-sm,
  .input-group-sm
  input[type="datetime-local"],
  input[type="month"].input-sm,
  .input-group-sm
  input[type="month"] {
    line-height: 30px; }
  input[type="date"].input-lg,
  .input-group-lg input[type="date"],
  input[type="time"].input-lg,
  .input-group-lg
  input[type="time"],
  input[type="datetime-local"].input-lg,
  .input-group-lg
  input[type="datetime-local"],
  input[type="month"].input-lg,
  .input-group-lg
  input[type="month"] {
    line-height: 46px; } }

.form-group {
  margin-bottom: 15px; }

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px; }
  .radio label,
  .checkbox label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer; }

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9; }

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; }

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer; }

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; }

input[type="radio"][disabled], input[type="radio"].disabled,
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled,
fieldset[disabled]
input[type="checkbox"] {
  cursor: not-allowed; }

.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled]
.checkbox-inline {
  cursor: not-allowed; }

.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled]
.checkbox label {
  cursor: not-allowed; }

.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 34px; }
  .form-control-static.input-lg, .form-control-static.input-sm {
    padding-left: 0;
    padding-right: 0; }

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

select.input-sm {
  height: 30px;
  line-height: 30px; }

textarea.input-sm,
select[multiple].input-sm {
  height: auto; }

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px; }

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto; }

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5; }

.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

select.input-lg {
  height: 46px;
  line-height: 46px; }

textarea.input-lg,
select[multiple].input-lg {
  height: auto; }

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px; }

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto; }

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.33333; }

.has-feedback {
  position: relative; }
  .has-feedback .form-control {
    padding-right: 42.5px; }

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none; }

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px; }

.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #3c763d; }

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-success .form-control:focus {
    border-color: #2b542c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168; }

.has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8; }

.has-success .form-control-feedback {
  color: #3c763d; }

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #8a6d3b; }

.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-warning .form-control:focus {
    border-color: #66512c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b; }

.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3; }

.has-warning .form-control-feedback {
  color: #8a6d3b; }

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #a94442; }

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-error .form-control:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }

.has-error .input-group-addon {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede; }

.has-error .form-control-feedback {
  color: #a94442; }

.has-feedback label ~ .form-control-feedback {
  top: 25px; }

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0; }

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373; }

@media (min-width: 828px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-static {
    display: inline-block; }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle; }
    .form-inline .input-group .input-group-addon,
    .form-inline .input-group .input-group-btn,
    .form-inline .input-group .form-control {
      width: auto; }
  .form-inline .input-group > .form-control {
    width: 100%; }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle; }
    .form-inline .radio label,
    .form-inline .checkbox label {
      padding-left: 0; }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0; }
  .form-inline .has-feedback .form-control-feedback {
    top: 0; } }

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px; }

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 27px; }

.form-horizontal .form-group {
  margin-left: -16px;
  margin-right: -16px; }
  .form-horizontal .form-group:before, .form-horizontal .form-group:after {
    content: " ";
    display: table; }
  .form-horizontal .form-group:after {
    clear: both; }

@media (min-width: 828px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px; } }

.form-horizontal .has-feedback .form-control-feedback {
  right: 16px; }

@media (min-width: 828px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 14.33333px;
    font-size: 18px; } }

@media (min-width: 828px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px; } }

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  .fade.in {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.in {
    display: block; }

tr.collapse.in {
  display: table-row; }

tbody.collapse.in {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease; }

.modal-open {
  overflow: hidden; }

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0; }
  .modal.fade .modal-dialog {
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out; }
  @media (max-width: 827px) {
    .modal.in .modal-dialog {
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -o-transform: translate(0, 0);
      transform: translate(0, 0);
      z-index: 100;
      width: 80%;
      margin: auto;
      margin-top: 100px; } }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0;
    filter: alpha(opacity=0); }
  .modal-backdrop.in {
    opacity: 0.5;
    filter: alpha(opacity=50); }

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.42857px; }

.modal-header .close {
  margin-top: -2px; }

.modal-title {
  margin: 0;
  line-height: 1.42857; }

.modal-body {
  position: relative;
  padding: 15px; }
  .modal-body > iframe {
    max-width: 100% !important;
    overflow: hidden; }

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5; }
  .modal-footer:before, .modal-footer:after {
    content: " ";
    display: table; }
  .modal-footer:after {
    clear: both; }
  .modal-footer .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; }
  .modal-footer .btn-group .btn + .btn {
    margin-left: -1px; }
  .modal-footer .btn-block + .btn-block {
    margin-left: 0; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 828px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto; }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }
  .modal-sm {
    width: 300px; } }

@media (min-width: 980px) {
  .modal-lg {
    width: 900px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  opacity: 0;
  filter: alpha(opacity=0); }
  .tooltip.in {
    opacity: 0.9;
    filter: alpha(opacity=90); }
  .tooltip.top {
    margin-top: -3px;
    padding: 5px 0; }
  .tooltip.right {
    margin-left: 3px;
    padding: 0 5px; }
  .tooltip.bottom {
    margin-top: 3px;
    padding: 5px 0; }
  .tooltip.left {
    margin-left: -3px;
    padding: 0 5px; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px; }

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  right: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000; }

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000; }

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 14px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }
  .popover.top {
    margin-top: -10px; }
  .popover.right {
    margin-left: 10px; }
  .popover.bottom {
    margin-top: 10px; }
  .popover.left {
    margin-left: -10px; }

.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0; }

.popover-content {
  padding: 9px 14px; }

.popover > .arrow, .popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.popover > .arrow {
  border-width: 11px; }

.popover > .arrow:after {
  border-width: 10px;
  content: ""; }

.popover.top > .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999999;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -11px; }
  .popover.top > .arrow:after {
    content: " ";
    bottom: 1px;
    margin-left: -10px;
    border-bottom-width: 0;
    border-top-color: #fff; }

.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999999;
  border-right-color: rgba(0, 0, 0, 0.25); }
  .popover.right > .arrow:after {
    content: " ";
    left: 1px;
    bottom: -10px;
    border-left-width: 0;
    border-right-color: #fff; }

.popover.bottom > .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -11px; }
  .popover.bottom > .arrow:after {
    content: " ";
    top: 1px;
    margin-left: -10px;
    border-top-width: 0;
    border-bottom-color: #fff; }

.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999999;
  border-left-color: rgba(0, 0, 0, 0.25); }
  .popover.left > .arrow:after {
    content: " ";
    right: 1px;
    border-right-width: 0;
    border-left-color: #fff;
    bottom: -10px; }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.hidden {
  display: none !important; }

.affix {
  position: fixed; }

@-ms-viewport {
  width: device-width; }

.visible-xs {
  display: none !important; }

.visible-sm {
  display: none !important; }

.visible-md {
  display: none !important; }

.visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 827px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table !important; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 827px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 827px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 827px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 828px) and (max-width: 979px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 828px) and (max-width: 979px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 828px) and (max-width: 979px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 828px) and (max-width: 979px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 980px) and (max-width: 1249px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }

@media (min-width: 980px) and (max-width: 1249px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 980px) and (max-width: 1249px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 980px) and (max-width: 1249px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1250px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1250px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1250px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1250px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 827px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 828px) and (max-width: 979px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 980px) and (max-width: 1249px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1250px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table !important; }
  tr.visible-print {
    display: table-row !important; }
  th.visible-print,
  td.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }
  @media print {
    .visible-print-block {
      display: block !important; } }

.visible-print-inline {
  display: none !important; }
  @media print {
    .visible-print-inline {
      display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }
  @media print {
    .visible-print-inline-block {
      display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

@keyframes diode {
  0%, 100% {
    transform: translateX(0); }
  50% {
    transform: translateX(100%); } }

@keyframes icon-collaboration {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes icon-better-value-arrow {
  0% {
    opacity: 0;
    transform: translateY(50px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes icon-better-value-circle {
  0% {
    stroke-dashoffset: 211; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes icon-better-value-dollar {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes icon-connected-signal1 {
  0%, 25% {
    opacity: 0; }
  50% {
    opacity: 1; } }

@keyframes icon-connected-signal2 {
  0%, 50% {
    opacity: 0; }
  75% {
    opacity: 1; } }

@keyframes icon-connected-signal3 {
  0%, 75% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes rotate {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes rotate-reverse {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(-360deg); } }

@keyframes icon-better-light-beam1 {
  0%, 12% {
    opacity: 0; }
  24% {
    opacity: 1; } }

@keyframes icon-better-light-beam2 {
  0%, 24% {
    opacity: 0; }
  37% {
    opacity: 1; } }

@keyframes icon-better-light-beam3 {
  0%, 37% {
    opacity: 0; }
  49% {
    opacity: 1; } }

@keyframes icon-better-light-beam4 {
  0%, 50% {
    opacity: 0; }
  62% {
    opacity: 1; } }

@keyframes icon-better-light-beam5 {
  0%, 62% {
    opacity: 0; }
  74% {
    opacity: 1; } }

@keyframes icon-better-light-beam6 {
  0%, 75% {
    opacity: 0; }
  87% {
    opacity: 1; } }

@keyframes icon-better-light-beam7 {
  0%, 88% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes icon-big-data-light1 {
  0%, 30%, 100% {
    opacity: 0; }
  15% {
    opacity: 1; } }

@keyframes icon-big-data-light2 {
  0%, 30%, 60%, 90%, 100% {
    opacity: 0; }
  45%, 75% {
    opacity: 1; } }

@keyframes icon-evolution-stem1 {
  0% {
    stroke-dashoffset: 50; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes icon-evolution-stem3 {
  0% {
    stroke-dashoffset: 165; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes icon-evolution-leafside {
  0% {
    stroke-dashoffset: 83; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes icon-evolution-vein {
  0% {
    stroke-dashoffset: 59; }
  100% {
    stroke-dashoffset: 24; } }

@keyframes panel1-header {
  0%, 33% {
    top: 50%; }
  100% {
    top: 0; } }

@keyframes panel1-content {
  0% {
    opacity: 0;
    top: 200px; }
  100% {
    opacity: 1;
    top: 0; } }

@keyframes panel-header {
  0% {
    opacity: 0;
    top: 50%;
    filter: blur(10px); }
  25%, 33% {
    opacity: 1;
    top: 50%;
    filter: blur(0); }
  100% {
    opacity: 1;
    top: 0; } }

@keyframes news-carousel {
  0% {
    opacity: 0;
    top: -200px; }
  66% {
    opacity: 1;
    top: 30px; }
  100% {
    opacity: 1;
    top: 0; } }

/* Typography Styles
** Define modular site text styles.
---------------------------------- */
html {
  font-size: 10px; }
  @media (max-height: 750px) {
    html {
      font-size: 9px; } }
  @media (max-width: 979px) {
    html {
      font-size: 8px; } }
  @media (max-width: 827px) {
    html {
      font-size: 10px;
      text-align: center; } }

body {
  font-size: 1.9rem;
  font-family: "Roboto", sans-serif;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

p, a, li, label {
  margin: 0;
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif; }
  @media (max-width: 827px) {
    p, a, li, label {
      text-align: center; } }
  p a, a a, li a, label a {
    font-size: inherit; }

a {
  transition-property: color;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  transition-delay: 0ms;
  text-decoration: none !important;
  color: #ffffff;
  font-family: "Roboto", sans-serif; }
  a:hover, a:focus {
    text-decoration: none !important;
    color: #2c2c2c; }

p, ul, ol {
  margin-bottom: 32px; }
  p:first-child, ul:first-child, ol:first-child {
    margin-top: 0; }

ul li {
  padding-left: 16px;
  position: relative; }

ol {
  list-style-position: inside;
  list-style-type: decimal; }

h1, h2, h3, h4, h5, h6 {
  font-family: serif;
  margin: 0;
  color: #ffffff; }
  @media (max-width: 827px) {
    h1, h2, h3, h4, h5, h6 {
      text-align: center; } }

h1, .rah1 {
  font-size: 3.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500; }

h2, .rah2 {
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500; }

h3, .rah3 {
  font-size: 2.4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500; }

h4, .rah4 {
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500; }

h5, .rah5 {
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500; }

h6, .rah6 {
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500; }

.cta {
  font-weight: 700;
  text-transform: uppercase;
  border: #005596 1px solid;
  padding: 10px 30px;
  color: #005596;
  background: transparent;
  display: inline-block; }
  .cta.yellow {
    border-color: #ffc000;
    color: #ffc000; }
  .cta:hover {
    background: #005596;
    color: #ffffff; }
    .cta:hover.yellow {
      background: transparent; }

.sans, .copy, .title {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif; }

.serif {
  font-family: serif;
  letter-spacing: 0.05em; }

.copy {
  font-size: 1.8rem;
  font-weight: 300;
  margin-bottom: 32px; }
  .copy.white {
    color: #ffffff; }
  .copy.blue {
    color: #5480c3; }

.title {
  font-size: 1.8rem;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 0.05em;
  margin-bottom: 32px; }
  .title.heavy {
    font-weight: 600; }
  .title.extra-large {
    font-size: 2.9rem; }
  .title.large {
    font-size: 2.3rem; }
  .title.small {
    font-size: 1.4rem; }
  .title.yellow {
    color: #ffc000; }
  .title.white {
    color: #ffffff; }
  .title.blue {
    color: #5480c3; }
  .title.dark_blue {
    color: #005596; }
  .title.black {
    color: #000000; }

.learn-more {
  color: #ffc000;
  font-weight: 300; }
  .learn-more:hover {
    color: #ffffff; }
    .learn-more:hover svg path {
      fill: #ffffff;
      stroke: #ffffff; }
  .learn-more svg {
    width: .8em;
    height: .8em;
    margin-left: 10px; }
    .learn-more svg path {
      stroke: #ffc000;
      fill: #ffc000;
      transition-property: all;
      transition-duration: 350ms;
      transition-timing-function: ease-out;
      transition-delay: 0ms; }

p:empty {
  display: none; }

strong {
  font-weight: 500; }

.text-left {
  text-align: left !important; }

.text-center {
  text-align: center !important; }

.text-right {
  text-align: right !important; }

.text_hidden {
  text-indent: -9999em; }

.text-uppercase {
  text-transform: uppercase; }

.text-lowercase {
  text-transform: lowercase; }

.footer-tagline {
  font-weight: 500;
  color: white;
  letter-spacing: 0.05em;
  padding: 1.0em;
  float: left; }

.footer-link {
  font-weight: 200;
  color: white;
  letter-spacing: 0.05em;
  padding: 1.0em;
  float: left; }

/*-----------------------------------------------
  Functional CSS Files
----------------------------------------------- */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*-----------------------------------------------
  Start Custom Theme CSS Below
----------------------------------------------- */
/*-----------------------------------------------
  Components SCSS - Tools / Modules
----------------------------------------------- */
.css_table {
  display: table;
  height: 100%;
  width: 100%; }
  .css_table .css_table_cell {
    display: table-cell;
    vertical-align: middle;
    float: none !important; }
    .css_table .css_table_cell.top {
      vertical-align: top; }
    .css_table .css_table_cell.bottom {
      vertical-align: bottom; }
  @media (max-width: 827px) {
    .css_table.mobile_block > .css_table_cell {
      display: block;
      padding: 0; } }

#pin-container {
  position: relative; }

.panel {
  position: relative;
  width: 100%;
  width: 100vw;
  height: calc(100% - 76px);
  height: calc(100vh - 76px);
  padding: 60px 0; }
  .panel:last-child {
    height: 100%;
    height: 100vh; }
  @media (max-height: 750px) {
    .panel {
      padding: 120px 0;
      height: auto; } }
  @media (max-width: 827px) {
    .panel {
      height: auto;
      padding: 60px 0; } }
  .panel > .container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    z-index: 50; }
    @media (max-height: 750px) {
      .panel > .container {
        transform: none;
        top: auto; } }
    @media (max-width: 827px) {
      .panel > .container {
        top: auto;
        transform: none; } }
  @media (max-width: 827px) {
    .panel header, .panel .title, .panel .video-thumb, .panel .copy, .panel .video-carousel, .panel .vignettes, .panel .slick-active {
      animation: none !important;
      opacity: 1 !important;
      transform: none !important;
      filter: none !important;
      top: 0 !important; } }
  @media (max-width: 827px) {
    .panel .slick-active {
      opacity: 1 !important;
      filter: none !important;
      top: 0 !important; } }

#panel1 {
  background: url("../../images/bg-panel1b.jpg");
  background-position: center center;
  background-size: cover; }
  #panel1 .copy, #panel1 .title {
    width: 70%;
    margin: 0 auto 64px auto;
    opacity: 0;
    transition-property: all;
    transition-duration: 350ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms; }
    @media (max-width: 827px) {
      #panel1 .copy, #panel1 .title {
        width: auto; } }
  #panel1 .video-thumb {
    opacity: 0; }
  #panel1 .copy {
    line-height: 1.7; }
  #panel1 h1.title {
    margin-bottom: 32px; }
  #panel1 .placeholder {
    visibility: hidden;
    margin-bottom: 32px;
    display: block; }
  #panel1 header {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    padding: 0 16px;
    transition-property: all;
    transition-duration: 350ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms; }
    #panel1 header .title {
      opacity: 1; }
  #panel1 .slick-active, #panel1 .arrow-prev, #panel1 .arrow-next {
    opacity: 0;
    transition-property: all;
    transition-duration: 350ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms; }
    @media (max-width: 827px) {
      #panel1 .slick-active, #panel1 .arrow-prev, #panel1 .arrow-next {
        opacity: 1; } }
  #panel1.active header {
    animation: panel1-header 1s forwards; }
  #panel1.active .content .copy, #panel1.active .content .title, #panel1.active .content .video-thumb {
    position: relative;
    animation: panel1-content 1s forwards;
    animation-delay: 0.5s; }
  #panel1.active .content .title.white {
    animation-delay: 0.8s; }
  #panel1.active .content .video-thumb {
    animation-delay: 1.1s; }
  #panel1.active .slick-active, #panel1.active .slide {
    opacity: 1; }
  #panel1.active .arrow-prev, #panel1.active .arrow-next {
    opacity: 1;
    transition-delay: 0.8s; }
  #panel1.active .panel-one-carousel {
    opacity: 1;
    transition-delay: 0.5s; }

#panel2 {
  background: url("../../images/bg-environment.jpg");
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.9); }
  @media (max-width: 979px) {
    #panel2 .mobile_block {
      height: auto; } }
  #panel2 .title, #panel2 .slide {
    opacity: 0;
    transform: translateX(50%);
    position: relative;
    transition-property: all;
    transition-duration: 500ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms; }
  #panel2 .arrow-prev, #panel2 .arrow-next {
    opacity: 0;
    transition-property: all;
    transition-duration: 500ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms; }
    @media (max-width: 768px) {
      #panel2 .arrow-prev, #panel2 .arrow-next {
        animation: none !important;
        opacity: 1 !important;
        left: 50%; } }
  @media (max-width: 768px) {
    #panel2 .arrow-prev {
      top: 0;
      transform: translateY(0) translateX(-50%) !important; } }
  @media (max-width: 768px) {
    #panel2 .arrow-next {
      top: 92%;
      transform: rotateZ(180deg) translateY(0) translateX(50%) !important; } }
  #panel2 header .title {
    filter: blur(10px);
    transition-property: transform, filter, opacity;
    transition-delay: .5s, 0s, 0s; }
  #panel2.active header .title {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0); }
  #panel2.active p.title {
    opacity: 1;
    transform: translateX(0);
    transition-delay: .8s; }
  #panel2.active .arrow-prev, #panel2.active .arrow-next {
    opacity: 1;
    transition-delay: 1.5s; }
  #panel2.active .arrow-prev {
    transform: translateY(0); }
  #panel2.active .arrow-next {
    transform: rotateZ(180deg) translateY(0); }
  #panel2.active .slide {
    opacity: 1;
    transform: translateX(0); }
    #panel2.active .slide[data-slick-index="0"] {
      transition-delay: 1.1s; }
    #panel2.active .slide[data-slick-index="1"] {
      transition-delay: 1.3s; }
    #panel2.active .slide[data-slick-index="2"] {
      transition-delay: 1.5s; }

#panel3 {
  background: url("../../images/bg-smartcast.jpg");
  background-position: center top;
  background-size: cover; }
  #panel3 header, #panel3 .content .copy, #panel3 .content .title, #panel3 .video-carousel {
    opacity: 0;
    transition-property: all;
    transition-duration: 350ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms; }
  #panel3 .copy, #panel3 .title {
    margin: 0 auto 64px auto; }
  #panel3 h1.title {
    margin-bottom: 32px; }
  #panel3 .placeholder {
    visibility: hidden;
    margin-bottom: 32px;
    display: block; }
  #panel3 header {
    position: absolute;
    width: 100%;
    padding: 0 16px;
    top: 50%;
    left: 0; }
  #panel3.active header {
    animation: panel-header 1s forwards; }
  #panel3.active .content .copy, #panel3.active .content .title, #panel3.active .content .video-carousel {
    position: relative;
    animation: panel1-content 1s forwards;
    animation-delay: 0.5s; }
  #panel3.active .content .title.white:not(.large) {
    animation-delay: 0.8s; }
  #panel3.active .content .video-carousel {
    animation-delay: 1.1s; }

#panel4 {
  background: url("../../images/bg-innovation.jpg");
  background-position: left bottom;
  background-size: contain;
  background-repeat: no-repeat; }
  @media (max-width: 827px) {
    #panel4 {
      background: #ffffff; } }
  #panel4 .title, #panel4 .video-thumb {
    transition-property: all;
    transition-duration: 500ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms;
    position: relative;
    opacity: 0;
    transform: translateX(-50%); }
  #panel4 header .title {
    filter: blur(10px);
    transition-property: transform, filter, opacity;
    transition-delay: .5s, 0s, 0s; }
  #panel4.active header .title {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0); }
  #panel4.active p.title {
    opacity: 1;
    transform: translateX(0);
    transition-delay: .8s; }
    #panel4.active p.title.blue {
      transition-delay: 1.1s; }
  #panel4.active .video-thumb {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 1.5s; }
  #panel4 .video-thumb {
    width: 500px;
    max-width: 100%; }
    @media (max-width: 1249px) {
      #panel4 .video-thumb {
        width: 400px; } }
    @media (max-width: 979px) {
      #panel4 .video-thumb {
        width: 300px; } }
  @media (max-width: 827px) {
    #panel4 > .container {
      padding: 0; } }
  @media (max-width: 827px) {
    #panel4 > .container > .css_table > .css_table_cell:first-child {
      display: table-footer-group; }
    #panel4 > .container > .css_table > .css_table_cell:last-child {
      display: table-caption; } }

#panel5 {
  padding-bottom: 100px;
  background: #fff2cc;
  /* Old browsers */
  background: -moz-radial-gradient(center center, circle contain, #fff2cc 0%, #ffd966 20%, #ffc000 100%);
  /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0%, center center, 100%, color-stop(0%, #fff2cc), color-stop(20%, #ffd966), color-stop(100%, #ffc000));
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center center, circle contain, #fff2cc 0%, #ffd966 20%, #ffc000 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(center center, circle contain, #fff2cc 0%, #ffd966 20%, #ffc000 100%);
  /* Opera 12+ */
  background: -ms-radial-gradient(center center, circle contain, #fff2cc 0%, #ffd966 20%, #ffc000 100%);
  /* IE10+ */
  background: radial-gradient(circle at center center, #fff2cc 0%, #ffd966 20%, #ffc000 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$center_color', endColorstr='$outer_color',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }
  @media (max-height: 850px) {
    #panel5 {
      height: auto;
      padding: 120px 0; }
      #panel5 .container {
        transform: none;
        top: auto; } }
  #panel5 .news-carousel {
    margin-top: 128px; }
    @media (max-width: 827px) {
      #panel5 .news-carousel {
        margin-top: 32px; } }
    #panel5 .news-carousel .row {
      margin-left: -32px;
      margin-right: -32px; }
    #panel5 .news-carousel [class*='col-'] {
      padding-left: 32px;
      padding-right: 32px; }
  #panel5 header {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    opacity: 0;
    padding: 0 16px;
    transition-property: all;
    transition-duration: 350ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms; }
  #panel5 p.title, #panel5 .vignettes {
    opacity: 0;
    transition-property: all;
    transition-duration: 350ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms; }
  #panel5 .news-item {
    opacity: 0; }
    #panel5 .news-item p.title {
      opacity: 1; }
  #panel5 .placeholder {
    visibility: hidden;
    margin-bottom: 64px;
    display: block; }
  #panel5 .slick-active, #panel5 .arrow-prev, #panel5 .arrow-next {
    opacity: 0;
    transition-property: all;
    transition-duration: 350ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms; }
    @media (max-width: 827px) {
      #panel5 .slick-active, #panel5 .arrow-prev, #panel5 .arrow-next {
        opacity: 1; } }
  #panel5 .news-carousel {
    opacity: 0;
    transition-property: all;
    transition-duration: 10ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms; }
  #panel5.active header {
    animation: panel-header 1s forwards; }
  #panel5.active .placeholder + p.title {
    position: relative;
    animation: panel1-content 1s forwards;
    animation-delay: 0.5s; }
  #panel5.active .slick-list {
    padding: 200px 0 30px 0;
    margin-top: -200px; }
  #panel5.active .slick-active, #panel5.active .news-item {
    opacity: 1; }
  #panel5.active .arrow-prev, #panel5.active .arrow-next {
    opacity: 1;
    transition-delay: 0.8s; }
  #panel5.active .news-carousel {
    opacity: 1;
    transition-delay: 0.5s; }
  #panel5.start .slick-active {
    position: relative;
    animation: news-carousel 1s backwards;
    animation-delay: 1s; }
    #panel5.start .slick-active + .slick-active {
      animation-delay: 1.1s; }
      #panel5.start .slick-active + .slick-active + .slick-active {
        animation-delay: 1.2s; }

#panel6 {
  background: #ffffff;
  padding: 0 !important;
  height: auto !important; }
  #panel6 .panel_header {
    background: #EBEBEB;
    padding: 25px 0;
    width: 100%; }
    #panel6 .panel_header .title {
      font-weight: 300;
      margin-bottom: 0 !important; }
      #panel6 .panel_header .title strong {
        font-weight: 700; }
  #panel6 .seven_wrapper {
    font-size: 0;
    display: table; }
    #panel6 .seven_wrapper:before, #panel6 .seven_wrapper:after {
      content: " ";
      display: table; }
    #panel6 .seven_wrapper:after {
      clear: both; }
    #panel6 .seven_wrapper .seven_left,
    #panel6 .seven_wrapper .seven_right {
      display: inline-block;
      width: 50%;
      width: 50vw;
      padding-left: 50px;
      padding-right: 50px;
      display: table-cell;
      padding-bottom: 100px; }
    #panel6 .seven_wrapper img {
      margin: 0 auto; }
    #panel6 .seven_wrapper .seven_left {
      background: #ffffff; }
      #panel6 .seven_wrapper .seven_left img {
        padding-bottom: 35px; }
    #panel6 .seven_wrapper .seven_right {
      background: #125892;
      padding-top: 160px; }
      #panel6 .seven_wrapper .seven_right img {
        max-width: 735px;
        width: 100%; }
  @media (max-width: 980px) {
    #panel6 {
      height: auto !important; }
      #panel6 .seven_wrapper {
        display: block; }
        #panel6 .seven_wrapper .seven_left,
        #panel6 .seven_wrapper .seven_right {
          display: block;
          width: 100%;
          height: auto;
          padding: 50px;
          float: none; } }

#main {
  padding-bottom: 81px; }
  @media (max-width: 980px) {
    #main {
      padding-bottom: 139px; } }
  @media (max-width: 600px) {
    #main {
      padding-bottom: 100px; } }

.vignette {
  border: solid 1px rgba(255, 255, 255, 0.75);
  margin-bottom: 32px;
  padding: 15px;
  position: relative;
  cursor: pointer;
  transition-property: all;
  transition-duration: 350ms;
  transition-timing-function: ease-out;
  transition-delay: 0ms; }
  .vignette:hover {
    background: rgba(255, 255, 255, 0.2); }
    .vignette:hover #icon-collaboration #person1, .vignette:hover #icon-collaboration #person2, .vignette:hover #icon-collaboration #person3 {
      animation: icon-collaboration 1s backwards; }
    .vignette:hover #icon-collaboration #person2 {
      animation-delay: 300ms; }
    .vignette:hover #icon-collaboration #person3 {
      animation-delay: 600ms; }
    .vignette:hover #icon-better-value #arrow {
      animation: icon-better-value-arrow 1s; }
    .vignette:hover #icon-better-value #circle {
      animation: icon-better-value-circle 1s; }
    .vignette:hover #icon-better-value #dollar {
      animation: icon-better-value-dollar 1s; }
    .vignette:hover #icon-connected #signal1 {
      animation: icon-connected-signal1 1.5s infinite; }
    .vignette:hover #icon-connected #signal2 {
      animation: icon-connected-signal2 1.5s infinite; }
    .vignette:hover #icon-connected #signal3 {
      animation: icon-connected-signal3 1.5s infinite; }
    .vignette:hover #icon-true-innovation #gear1-rotate {
      animation: rotate infinite linear 3s; }
    .vignette:hover #icon-true-innovation #gear2-rotate {
      animation: rotate-reverse infinite linear 3s; }
    .vignette:hover #icon-better-light #beam1 {
      animation: icon-better-light-beam1 2s infinite; }
    .vignette:hover #icon-better-light #beam2 {
      animation: icon-better-light-beam2 2s infinite; }
    .vignette:hover #icon-better-light #beam3 {
      animation: icon-better-light-beam3 2s infinite; }
    .vignette:hover #icon-better-light #beam4 {
      animation: icon-better-light-beam4 2s infinite; }
    .vignette:hover #icon-better-light #beam5 {
      animation: icon-better-light-beam5 2s infinite; }
    .vignette:hover #icon-better-light #beam6 {
      animation: icon-better-light-beam6 2s infinite; }
    .vignette:hover #icon-better-light #beam7 {
      animation: icon-better-light-beam7 2s infinite; }
    .vignette:hover #icon-big-data #light1 {
      animation: icon-big-data-light1 2s infinite backwards; }
    .vignette:hover #icon-big-data #light2 {
      animation: icon-big-data-light2 2s infinite backwards; }
    .vignette:hover #icon-evolution #stem1 {
      animation: icon-evolution-stem1 500ms backwards; }
    .vignette:hover #icon-evolution #stem2 {
      animation: icon-evolution-stem1 500ms backwards;
      animation-delay: 200ms; }
    .vignette:hover #icon-evolution #stem3 {
      animation: icon-evolution-stem3 800ms backwards;
      animation-delay: 400ms; }
    .vignette:hover #icon-evolution #leaf1side1, .vignette:hover #icon-evolution #leaf1side2, .vignette:hover #icon-evolution #leaf2side1, .vignette:hover #icon-evolution #leaf2side2 {
      animation: icon-evolution-leafside 500ms backwards;
      animation-delay: 700ms; }
    .vignette:hover #icon-evolution #leaf1vein1, .vignette:hover #icon-evolution #leaf1vein2, .vignette:hover #icon-evolution #leaf2vein1, .vignette:hover #icon-evolution #leaf2vein2 {
      animation: icon-evolution-vein 500ms backwards;
      animation-delay: 700ms; }
    .vignette:hover #icon-evolution #stem4 {
      animation: icon-evolution-stem3 800ms backwards;
      animation-delay: 1s; }
    .vignette:hover #icon-evolution #leaf2side1, .vignette:hover #icon-evolution #leaf2side2, .vignette:hover #icon-evolution #leaf2vein1, .vignette:hover #icon-evolution #leaf2vein2 {
      animation-delay: 1.2s; }
  .vignette .title {
    font-size: 1.5rem;
    margin: 0;
    text-align: center; }
  .vignette .vig-icon {
    height: 60px;
    margin-bottom: 10px; }
    .vignette .vig-icon.small {
      height: 50px;
      margin-top: 10px; }
  .vignette .play-button-circle {
    width: 24px;
    height: 24px;
    vertical-align: -7px;
    margin-left: 5px; }
    .vignette .play-button-circle .circle-bg {
      opacity: .2; }

#icon-collaboration {
  fill: #ffffff; }

#icon-better-value #arrow, #icon-better-value #dollar {
  fill: #ffffff;
  position: relative; }

#icon-better-value #circle {
  stroke: #ffffff;
  stroke-dasharray: 211; }

#icon-connected {
  fill: #ffffff; }

#icon-true-innovation {
  fill: #ffffff; }
  #icon-true-innovation #gear1, #icon-true-innovation #gear2 {
    transform-origin: 50% 50%; }
    .ff #icon-true-innovation #gear1, .ff #icon-true-innovation #gear2 {
      transform-origin: 0 0; }
  #icon-true-innovation #gear1-ff-correct {
    transform: translate(46px, 82px); }
    #icon-true-innovation #gear1-ff-correct #gear1 {
      transform: translate(-46px, -82px); }
  #icon-true-innovation #gear2-ff-correct {
    transform: translate(94px, 34px); }
    #icon-true-innovation #gear2-ff-correct #gear2 {
      transform: translate(-94px, -34px); }

#icon-better-light {
  fill: #ffffff; }

#icon-big-data {
  fill: #ffffff; }

#icon-evolution {
  stroke: #ffffff; }
  #icon-evolution #stem1, #icon-evolution #stem2 {
    stroke-dasharray: 50; }
  #icon-evolution #stem3, #icon-evolution #stem4 {
    stroke-dasharray: 165; }
  #icon-evolution #leaf1side1, #icon-evolution #leaf1side2, #icon-evolution #leaf2side1, #icon-evolution #leaf2side2 {
    stroke-dasharray: 83; }
  #icon-evolution #leaf1vein1, #icon-evolution #leaf1vein2, #icon-evolution #leaf2vein1, #icon-evolution #leaf2vein2 {
    stroke-dasharray: 59; }

.video-thumb {
  position: relative;
  margin-bottom: 32px;
  transition-property: all;
  transition-duration: 350ms;
  transition-timing-function: ease-out;
  transition-delay: 0ms; }
  .video-thumb svg {
    transition-property: all;
    transition-duration: 350ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms; }
  .video-thumb:hover svg {
    transform: scale3d(1.2, 1.2, 1.2); }
  @media (max-width: 827px) {
    .video-thumb {
      margin: 40px auto 0 auto;
      width: 300px;
      max-width: 100%; } }
  .video-thumb img {
    width: 100%; }

.mobile-iframe {
  display: none;
  margin: 40px auto 0 auto;
  width: 300px;
  height: 175px;
  max-width: 100%; }

.carousel {
  position: relative; }
  @media (max-width: 827px) {
    .carousel {
      width: 300px;
      max-width: 100%;
      margin: auto;
      float: none; } }
  .carousel .slide {
    margin: 0 32px;
    height: auto; }
    @media (max-width: 827px) {
      .carousel .slide {
        margin: 0 10px; } }
  .carousel .video-thumb {
    margin-bottom: 0; }
  .carousel .arrow-prev, .carousel .arrow-next {
    position: absolute;
    top: 50%;
    width: 35px;
    height: 58px;
    cursor: pointer;
    z-index: 999; }
    .carousel .arrow-prev:hover path, .carousel .arrow-next:hover path {
      stroke: #5480c3;
      transition-property: all;
      transition-duration: 350ms;
      transition-timing-function: ease-out;
      transition-delay: 0ms; }
  .carousel .arrow-prev {
    left: -50px;
    transform: rotateZ(-90deg) translateX(75%); }
    @media (max-width: 827px) {
      .carousel .arrow-prev {
        left: -20px; } }
  .carousel .arrow-next {
    right: -50px;
    transform: rotateZ(90deg) translateX(-75%); }
    @media (max-width: 827px) {
      .carousel .arrow-next {
        right: -20px; } }
  .carousel.vertical .slide {
    margin: 16px 0; }
    @media (max-width: 827px) {
      .carousel.vertical .slide {
        margin: 10px 0; } }
  .carousel.vertical .arrow-prev {
    left: -30px;
    transform: translateX(0) translateY(75%); }
  .carousel.vertical .arrow-next {
    right: -30px;
    transform: rotateZ(180deg) translateY(75%); }

@media (max-width: 1249px) {
  .news-carousel {
    max-width: 80%;
    margin: 64px auto; } }

@media (max-width: 979px) {
  .news-carousel {
    max-width: 400px; } }

.news-carousel .news-item {
  margin: 0 32px; }
  @media (max-width: 827px) {
    .news-carousel .news-item {
      margin: 0 10px; } }

.news-carousel .arrow-prev path, .news-carousel .arrow-next path {
  stroke: #5480c3; }

.news-carousel .arrow-prev:hover path, .news-carousel .arrow-next:hover path {
  stroke: #000000; }

.news-item {
  background: #ffffff;
  padding: 44px;
  padding-bottom: 75px;
  text-align: left;
  min-height: 441px;
  max-height: 442px; }
  @media (max-width: 827px) {
    .news-item {
      padding: 25px; } }
  .news-item img {
    width: 100%;
    margin-bottom: 32px; }
  .news-item .title {
    text-align: left; }
  .news-item .title.small {
    margin: 0; }
  .news-item a {
    color: inherit;
    font-size: inherit; }
    .news-item a:hover {
      color: #5480c3; }
  .news-item .blue a:hover {
    color: #000000; }
  .news-item .read-more {
    position: absolute !important;
    bottom: 44px !important;
    top: auto !important; }

#bullet-nav {
  position: fixed;
  z-index: 500;
  left: 20px;
  top: 50%;
  transform: translateY(-50%); }
  @media (max-width: 827px) {
    #bullet-nav {
      display: none; } }
  #bullet-nav ul {
    margin: 0; }
  #bullet-nav li {
    padding: 20px 0; }
    #bullet-nav li.dark .bullet {
      background: #5480c3; }
    #bullet-nav li.dark a:hover .bullet {
      background: #5480c3; }
    #bullet-nav li.dark a.active .bullet {
      background: #ffc000; }
    #bullet-nav li.dark .panel-link {
      color: #5480c3; }
  #bullet-nav a {
    position: relative;
    display: block; }
    #bullet-nav a:hover {
      color: #ffffff; }
      #bullet-nav a:hover .panel-link-wrapper {
        left: -20px;
        opacity: 1; }
      #bullet-nav a:hover .bullet {
        background: #ffffff; }
    #bullet-nav a.active .bullet {
      background: #ffc000;
      box-shadow: 0px 0px 18px 7px #ffffff; }
    #bullet-nav a:focus {
      outline: none; }
  #bullet-nav .bullet {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #e2e2e2;
    position: relative;
    z-index: 550;
    transition-property: all;
    transition-duration: 350ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms; }
  #bullet-nav .panel-link-wrapper {
    width: 410px;
    position: absolute;
    left: -380px;
    top: 50%;
    opacity: 0;
    z-index: 500;
    transition-property: all;
    transition-duration: 350ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms;
    transition-property: opacity, left;
    transition-delay: 100ms, 0ms; }
  #bullet-nav .panel-link {
    display: inline-block;
    padding: 10px 20px 10px 50px;
    transform: translateY(-50%);
    background: rgba(226, 226, 226, 0.85);
    line-height: 1.3;
    text-transform: uppercase;
    font-size: 1.4rem;
    color: #2c2c2c; }

#half-diode {
  height: 240px;
  width: 240px;
  position: fixed;
  bottom: 120px;
  right: 0;
  z-index: 25; }
  @media (max-width: 827px) {
    #half-diode {
      width: 80px;
      height: 80px;
      bottom: 80px; } }
  #half-diode path {
    transition-property: all;
    transition-duration: 350ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms; }
  #half-diode.white path {
    fill: #ffffff; }
  #half-diode.yellow path {
    fill: #ffc000; }
  #half-diode.blue path {
    fill: #005596; }
  #half-diode .animate#bottom, #half-diode .animate#middle, #half-diode .animate#top {
    animation: diode 300ms ease forwards;
    transition-delay: 150ms;
    transition-duration: 1ms; }
  #half-diode .animate#middle {
    animation-delay: 50ms;
    transition-delay: 200ms; }
  #half-diode .animate#bottom {
    animation-delay: 100ms;
    transition-delay: 250ms; }

.play-button {
  width: 35px;
  height: 35px; }
  .play-button path {
    fill: none;
    stroke: #ffc000;
    stroke-width: 12; }
  a .play-button {
    vertical-align: -8px;
    margin-left: 10px; }

.play-button-circle {
  width: 100px;
  height: 100px;
  cursor: pointer; }
  @media (max-width: 979px) {
    .play-button-circle {
      width: 60px;
      height: 60px; } }
  .play-button-circle .play-icon {
    fill: none;
    stroke: #ffffff;
    stroke-width: 4; }
  .play-button-circle .circle-bg {
    opacity: .5; }
  .play-button-circle.white .circle-bg {
    fill: #ffffff; }
  .play-button-circle.yellow .circle-bg {
    fill: #ffc000; }
  .play-button-circle.light-blue .circle-bg {
    fill: #5480c3; }
  .play-button-circle.dark-blue .circle-bg {
    fill: #005596; }

.play-button-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer; }

.modal.fade .modal-dialog {
  transform: none;
  width: 60%; }
  @media (max-width: 827px) {
    .modal.fade .modal-dialog {
      width: 100%; } }

.modal.fade .modal-content {
  box-shadow: none;
  border-radius: 0;
  text-align: right; }

.modal.fade .modal-body {
  padding-top: 0; }

.modal.fade .iframe-wrapper {
  width: 100%;
  padding-bottom: 56.2%;
  position: relative; }
  .modal.fade .iframe-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal.fade .close {
  background: transparent;
  border: none;
  cursor: pointer;
  margin-right: 10px; }

#header {
  width: 100%;
  position: fixed;
  padding: 10px 30px;
  z-index: 9999;
  bottom: 0;
  border-top: 2px solid #ffffff;
  border-bottom: 6px solid #ffc000;
  opacity: .9;
  white-space: nowrap;
  background: #005596; }
  @media (max-height: 750px) and (min-width: 979px) {
    #header {
      padding: 0 30px; } }
  @media (max-width: 827px) {
    #header {
      padding: 10px 10px 5px 10px; } }
  #header .share-actions {
    border: 2px solid black !important;
    position: absolute;
    bottom: 100%;
    right: 30px;
    background: white;
    border: solid 1px #333333; }
    #header .share-actions .title {
      margin-bottom: 0; }
    #header .share-actions ul {
      margin: 0; }
    #header .share-actions li {
      border-top: solid 1px #c9c9c9;
      padding: 0; }
      #header .share-actions li:first-child {
        border-top: none; }
    #header .share-actions a {
      padding: 10px 50px 10px 30px;
      display: block;
      color: #333333;
      font-weight: 400;
      font-size: 1.4rem;
      letter-spacing: .1em; }
      #header .share-actions a:hover {
        background: #e2e2e2; }
      #header .share-actions a .sprite {
        margin-left: 20px;
        display: block;
        width: 25px;
        position: absolute;
        right: 10px;
        top: 10px; }
        #header .share-actions a .sprite.facebook {
          height: 25px;
          width: 20px;
          top: 7px;
          background: url("../../images/social-sprite-retina.png") 0 0 no-repeat;
          background-size: 25px 120px !important; }
          @media (min-moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2 / 1), (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
            #header .share-actions a .sprite.facebook {
              background-image: url("../../images/social-sprite-retina.png"); } }
        #header .share-actions a .sprite.twitter {
          height: 25px;
          background: url("../../images/social-sprite-retina.png") 0 -34px no-repeat;
          background-size: 25px 120px !important; }
          @media (min-moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2 / 1), (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
            #header .share-actions a .sprite.twitter {
              background-image: url("../../images/social-sprite-retina.png"); } }
        #header .share-actions a .sprite.google {
          height: 25px;
          background: url("../../images/social-sprite-retina.png") 0 -75px no-repeat;
          background-size: 25px 120px !important; }
          @media (min-moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2 / 1), (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
            #header .share-actions a .sprite.google {
              background-image: url("../../images/social-sprite-retina.png"); } }
        #header .share-actions a .sprite.email {
          height: 25px;
          background: url("../../images/social-sprite-retina.png") 0 -100px no-repeat;
          background-size: 25px 120px !important; }
          @media (min-moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2 / 1), (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
            #header .share-actions a .sprite.email {
              background-image: url("../../images/social-sprite-retina.png"); } }
  #header .footer-tagline {
    height: 50px !important;
    padding: 15px !important;
    font-size: 1.9rem; }
  #header .hover-animate {
    border-left: solid 1px rgba(255, 255, 255, 0.75);
    padding: 15px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    transition-property: all;
    transition-duration: 350ms;
    transition-timing-function: ease-out;
    transition-delay: 0ms; }
    #header .hover-animate:hover {
      background: rgba(255, 255, 255, 0.2);
      color: white; }
    #header .hover-animate .share-container {
      padding: 10px 0;
      cursor: pointer;
      font-size: 0; }
      @media (max-width: 827px) {
        #header .hover-animate .share-container {
          margin-right: 0;
          display: inline-block;
          padding: 0; }
          #header .hover-animate .share-container .title {
            font-size: 10px;
            line-height: 1;
            display: block;
            margin-top: 10px; } }
    #header .hover-animate.share-trigger {
      padding: 11.5px 15px; }
    #header .hover-animate .title {
      margin: 0; }
      @media (max-width: 827px) {
        #header .hover-animate .title {
          font-size: 12px; } }
    @media (max-width: 979px) {
      #header .hover-animate .logo {
        margin-top: 0px; } }
    #header .hover-animate #share-icon {
      vertical-align: -7px;
      margin: 0 10px; }
      @media (max-width: 827px) {
        #header .hover-animate #share-icon {
          width: 20px;
          height: 22px;
          display: block;
          margin: auto; } }
      #header .hover-animate #share-icon path {
        fill: white; }
    #header .hover-animate .back-to-top {
      margin-right: 20px; }
      @media (max-width: 827px) {
        #header .hover-animate .back-to-top {
          float: left; } }
    #header .hover-animate #arrow-icon {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      vertical-align: -7px; }
      #header .hover-animate #arrow-icon path {
        stroke: white;
        stroke-width: 10; }
  @media (max-width: 600px) {
    #header .fixed-footer-container {
      display: flex;
      flex-direction: column;
      position: relative; }
      #header .fixed-footer-container .footer-logo {
        float: none;
        order: 1;
        border-bottom: 1px solid #fff;
        padding-bottom: 10px;
        margin-bottom: 10px; }
        #header .fixed-footer-container .footer-logo > img {
          max-width: 200px;
          margin: 0; }
      #header .fixed-footer-container .share {
        float: none;
        order: 2;
        position: static; }
        #header .fixed-footer-container .share .share-container {
          display: flex;
          flex-wrap: wrap; }
          #header .fixed-footer-container .share .share-container .footer-tagline {
            display: none; }
          #header .fixed-footer-container .share .share-container .footer-link {
            width: 50%;
            padding: 5px 0 10px;
            font-size: 1.4rem;
            line-height: 1;
            border: none; }
          #header .fixed-footer-container .share .share-container .share-trigger {
            position: absolute;
            top: -10px;
            right: 0;
            z-index: 2;
            border: none; } }
  @media (min-width: 980px) {
    #header .share-container {
      float: right !important; } }
  @media (min-width: 980px) {
    #header .footer-logo img {
      float: left !important; } }
  @media (max-width: 980px) {
    #header {
      text-align: center; }
      #header .footer-tagline,
      #header .footer-link {
        float: none !important; }
      #header .footer-logo img {
        margin: 10px auto 0; } }

#main {
  overflow: hidden;
  display: block; }

#panel7 {
  padding: 250px 0 250px 0;
  background-position: center center;
  background-size: cover;
  position: relative; }
  #panel7 .title {
    margin-bottom: 60px; }
