/*-----------------------------------------------
  Components SCSS - Tools / Modules
----------------------------------------------- */

// CSS Table Classes for vertical alignment
@import "components/_css_table.scss";

// General Panel Styles
@import "components/panel.scss";

// Panel 1 Specific Styles
@import "components/panel-1.scss";

// Panel 2 Specific Styles
@import "components/panel-2.scss";

// Panel 3 Specific Styles
@import "components/panel-3.scss";

// Panel 4 Specific Styles
@import "components/panel-4.scss";

// Panel 5 Specific Styles
@import "components/panel-5.scss";

// Panel 6 Specific Styles
@import "components/panel-6.scss";

// Panel 5 Specific Styles
@import "components/vignette.scss";

// Video Thumbnail Styles
@import "components/video-thumb.scss";

// Carousel Styles
@import "components/carousel.scss";

// Video Thumbnail Styles
@import "components/news-item.scss";

// Bullet Navigation Styles
@import "components/bullet-nav.scss";

// Half Diode SVG Styles
@import "components/half-diode.scss";

// Play Button Styles
@import "components/play-button.scss";

// Video Modal Styles
@import "components/modal.scss";

// Footer Styles
@import "components/footer.scss";

// Basic form styling
//@import "components/_forms.scss";

// Basic accordion styling and functionality
//@import "components/_accordions.scss";

// Basic tab styling and functionality
//@import "components/_tabs.scss";
