// Core variables overrides
$breakpoint_padding: 120px;
$grid-gutter-width: 32px;

$lg-content-width: 1130px;
$md-content-width: 860px;
$sm-content-width: 708px;
$xs-content-width: 480px;


$icon-font-path: 'css/bootstrap/fonts/bootstrap/';


// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  $xs-content-width + $breakpoint_padding;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  $sm-content-width + $breakpoint_padding;
$screen-sm-min:              $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  $md-content-width + $breakpoint_padding;
$screen-md-min:              $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  $lg-content-width + $breakpoint_padding;
$screen-lg-min:              $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

// Small screen / tablet
$container-tablet:             ($sm-content-width + $grid-gutter-width);
// For `$screen-sm-min` and up.
$container-sm:                 $container-tablet;

// Medium screen / desktop
$container-desktop:            ($md-content-width + $grid-gutter-width);
// For `$screen-md-min` and up.
$container-md:                 $container-desktop;

// Large screen / wide desktop
$container-large-desktop:      ($lg-content-width + $grid-gutter-width);
//For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop;


$margin: $grid-gutter-width;


$phone_small: $screen-xs;
$phone: $screen-xs-max;
$tablet: $screen-sm-max;
$desktop: $screen-md-max;

$medium_screen_height: 850px;
