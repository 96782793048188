#panel2 {
    background:url("../../images/bg-environment.jpg");
    background-position:left center;
    background-size:contain;
    background-repeat:no-repeat;
    background-color:rgba(255,255,255,0.9);

    .container {
        //background:rgba(255,255,255,0.9);
    }

    .mobile_block {
        @media (max-width: $tablet) {
            height: auto;
        }
    }
    
    .title, .slide {
        opacity:0;
        transform:translateX(50%);
        position:relative;
        @include ra_transition($duration:500ms);
    }

    .arrow-prev, .arrow-next {
        opacity: 0;
        @include ra_transition($duration:500ms);

        @media (max-width: 768px) {
            animation:none !important;
            opacity:1 !important;
            left: 50%;
        }
    }

    .arrow-prev {
        @media (max-width: 768px) {
            top: 0;
            transform: translateY(0) translateX(-50%) !important;
        }
    }

    .arrow-next {
        @media (max-width: 768px) {
            top: 92%;
            transform: rotateZ(180deg) translateY(0) translateX(50%) !important;
        }
    }

    header {
        .title {
            filter:blur(10px);
            transition-property:transform, filter, opacity;
            transition-delay:.5s, 0s, 0s;
        }
    }

    &.active {
        header {
            .title {
                opacity:1;
                filter:blur(0);
                transform:translateX(0);
            }
        }

        p.title {
            opacity:1;
            transform:translateX(0);
            transition-delay:.8s;
        }

        .arrow-prev, .arrow-next {
            opacity:1;
            transition-delay:1.5s;
        }

        .arrow-prev {
            transform: translateY(0);
        }

        .arrow-next {
            transform: rotateZ(180deg) translateY(0);
        }

        .slide {
            opacity:1;
            transform:translateX(0);

            &[data-slick-index="0"]{
                transition-delay:1.1s;
            }

            &[data-slick-index="1"] {
                transition-delay:1.3s;
            }
            &[data-slick-index="2"] {
                transition-delay:1.5s;
            }

        }
    }
}
