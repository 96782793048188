/*-----------------------------------------------
  Framework SCSS - Resets / Bootstrap / Typography / Functional CSS
----------------------------------------------- */

// Meyers Reset Based on work by Eric Meyer: http://meyerweb.com/eric/tools/css/reset/index.html
@import "framework/_reset-meyer.scss";

// Rivers Reset - takes care of some of the more agressive Meyer's reset rules
@import "framework/_reset-rivers.scss";


/*-----------------------------------------------
  Bootstrap Less
----------------------------------------------- */

/*
//  Over ride Bootstrap Core Variables in ../bootstrap/stylesheets/bootstrap/__custom-variables.scss
// to recalculate grid system appropriately ***
----------------------------------------------- */

// Boostrap Core LESS
@import "../bootstrap/stylesheets/_bootstrap.scss";


// Site Keyframes
@import "framework/_keyframes.scss";


// Typography
@import "framework/_typography.scss";


/*-----------------------------------------------
  Functional CSS Files
----------------------------------------------- */

// Slick Slider
@import "../../bower_components/slick-carousel/slick/slick.scss";
