.play-button {
    width:35px;
    height:35px;

    path {
        fill:none;
        stroke:$yellow;
        stroke-width:12;
    }

    a & {
        vertical-align:-8px;
        margin-left:10px;
    }
}

.play-button-circle {
    width:100px;
    height:100px;
    cursor:pointer;

    @media (max-width:$tablet) {
        width:60px;
        height:60px;
    }

    .play-icon {
        fill:none;
        stroke:$white;
        stroke-width:4;
    }

    .circle-bg {
        opacity:.5;
    }

    &.white {
        .circle-bg {
            fill:$white;
        }
    }

    &.yellow {
        .circle-bg {
            fill:$yellow;
        }
    }

    &.light-blue {
        .circle-bg {
            fill:$light_blue;
        }
    }

    &.dark-blue {
        .circle-bg {
            fill:$dark_blue;
        }
    }
}

.play-button-overlay {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    text-align:center;
    cursor:pointer;
}
