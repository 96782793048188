.modal.fade {

    .modal-dialog {
        transform:none;
        width:60%;

        @media (max-width:$phone) {
            width:100%;
        }
    }

    .modal-content {
        box-shadow:none;
        border-radius:0;
        text-align:right;
    }

    .modal-body {
        padding-top:0;
    }

    .iframe-wrapper {
        width:100%;
        padding-bottom:56.2%;
        position:relative;

        iframe {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }
    }

    .close {
        background: transparent;
        border: none;
        cursor: pointer;
        margin-right:10px;
    }
}
