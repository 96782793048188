#panel1 {

    background:url("../../images/bg-panel1b.jpg");
    background-position:center center;
    background-size:cover;

    .copy, .title {
        width:70%;
        margin:0 auto $margin*2 auto;
        opacity:0;
        @include ra_transition();

        @media (max-width:$phone) {
            width:auto;
        }
    }

    .video-thumb {
        opacity:0;
    }

    .copy {
        line-height:1.7;
    }

    h1.title {
        margin-bottom:$margin;

    }

    .placeholder {
        visibility:hidden;
        margin-bottom:$margin;
        display:block;
    }

    $opening_timing: 1s;

    header {
        position:absolute;
        width:100%;
        top:50%;
        left:0;
        padding:0 $margin/2;
        @include ra_transition();

        .title {
            opacity:1;
        }
    }

    .slick-active, .arrow-prev, .arrow-next {
        opacity:0;
        @include ra_transition();

        @media (max-width:$phone) {
            opacity:1;
        }
    }

    &.active {
        header {
            animation:panel1-header $opening_timing forwards;
        }

        .content {

            .copy, .title, .video-thumb {
                position:relative;
                animation:panel1-content 1s forwards;
                animation-delay:$opening_timing - .5;
            }

            .title.white {
                animation-delay:$opening_timing - .2;
            }

            .video-thumb {
                animation-delay:$opening_timing + .1;
            }
        }

        .slick-active, .slide {
            opacity:1;
        }

        .arrow-prev, .arrow-next {
            opacity:1;
            transition-delay:$opening_timing - .2;
        }

        .panel-one-carousel {
            opacity:1;
            transition-delay:$opening_timing - .5s;
        }
    }
}
