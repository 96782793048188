.css_table {
  display:table;
  height:100%;
  width:100%;

  .css_table_cell {
    display:table-cell;
    vertical-align:middle;
    float:none !important;

    &.top {
      vertical-align:top;
    }

    &.bottom {
      vertical-align:bottom;
    }
  }

  &.mobile_block {

    > .css_table_cell {
        @media (max-width:$phone) {
            display:block;
            padding:0;
        }
    }
  }
}
