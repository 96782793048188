@mixin diodeAnimation() {
    &#bottom, &#middle, &#top {
        animation:diode 300ms ease forwards;

        transition-delay:150ms;
        transition-duration:1ms;
    }

    &#middle {
        animation-delay:50ms;
        transition-delay:200ms;
    }

    &#bottom {
        animation-delay:100ms;
        transition-delay:250ms;
    }
}

#half-diode {
    height:240px;
    width:240px;
    position:fixed;
    bottom:120px;
    right:0;
    z-index:25;

    @media (max-width:$phone) {
        width:80px;
        height:80px;
        bottom:80px;
    }

    path {
        @include ra_transition();
    }

    &.white {
        path {
            fill:$white;
        }
    }

    &.yellow {
        path {
            fill:$yellow;
        }
    }

    &.blue {
        path {
            fill:$dark_blue;
        }
    }

    .animate {
        @include diodeAnimation();
    }
}
