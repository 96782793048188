@keyframes diode {
    0%, 100% {
        transform:translateX(0);
    }
    50% {

        transform:translateX(100%);
    }
}

@keyframes icon-collaboration {
    0% {
        opacity:0;
    }

    100% {
        opacity:1;
    }
}

@keyframes icon-better-value-arrow {
    0% {
        opacity:0;
        transform:translateY(50px);
    }

    100% {
        opacity:1;
        transform:translateY(0);
    }
}

@keyframes icon-better-value-circle {
    0% {
        stroke-dashoffset:211;
    }

    100% {
        stroke-dashoffset:0;
    }
}

@keyframes icon-better-value-dollar {
    0% {
        opacity:0;
    }

    100% {
        opacity:1;
    }
}

@keyframes icon-connected-signal1 {
    0%, 25% {
        opacity:0;
    }

    50% {
        opacity:1;
    }
}

@keyframes icon-connected-signal2 {
    0%, 50% {
        opacity:0;
    }

    75% {
        opacity:1;
    }
}

@keyframes icon-connected-signal3 {
    0%, 75% {
        opacity:0;
    }

    100% {
        opacity:1;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes rotate-reverse {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

@keyframes icon-better-light-beam1 {
    0%, 12% {
        opacity:0;
    }

    24% {
        opacity:1;
    }
}

@keyframes icon-better-light-beam2 {
    0%, 24% {
        opacity:0;
    }

    37% {
        opacity:1;
    }
}

@keyframes icon-better-light-beam3 {
    0%, 37% {
        opacity:0;
    }

    49% {
        opacity:1;
    }
}

@keyframes icon-better-light-beam4 {
    0%, 50% {
        opacity:0;
    }

    62% {
        opacity:1;
    }
}

@keyframes icon-better-light-beam5 {
    0%, 62% {
        opacity:0;
    }

    74% {
        opacity:1;
    }
}

@keyframes icon-better-light-beam6 {
    0%, 75% {
        opacity:0;
    }

    87% {
        opacity:1;
    }
}

@keyframes icon-better-light-beam7 {
    0%, 88% {
        opacity:0;
    }

    100% {
        opacity:1;
    }
}

@keyframes icon-big-data-light1 {
    0%, 30%, 100% {
        opacity:0;
    }

    15% {
        opacity:1;
    }
}

@keyframes icon-big-data-light2 {
    0%, 30%, 60%, 90%, 100% {
        opacity:0;
    }

    45%, 75% {
        opacity:1;
    }
}

@keyframes icon-evolution-stem1 {
    0% {
        stroke-dashoffset:50;
    }

    100% {
        stroke-dashoffset:0;
    }
}

@keyframes icon-evolution-stem3 {
    0% {
        stroke-dashoffset:165;
    }

    100% {
        stroke-dashoffset:0;
    }
}

@keyframes icon-evolution-leafside {
    0% {
        stroke-dashoffset:83;
    }

    100% {
        stroke-dashoffset:0;
    }
}

@keyframes icon-evolution-vein {
    0% {
        stroke-dashoffset:59;
    }

    100% {
        stroke-dashoffset:24;
    }
}

@keyframes panel1-header {
    0%, 33%{
        top:50%;
    }

    100% {
        top:0;
    }
}

@keyframes panel1-content {

    0% {
        opacity:0;
        top:200px
    }

    100% {
        opacity:1;
        top:0;
    }
}

@keyframes panel-header {
    0%{
        opacity:0;
        top:50%;
        filter:blur(10px);
    }

    25%, 33% {
        opacity:1;
        top:50%;
        filter:blur(0);
    }

    100% {
        opacity:1;
        top:0;
    }
}

@keyframes news-carousel {
    0% {
        opacity:0;
        top:-200px;
    }

    66% {
        opacity:1;
        top:30px;
    }

    100% {
        opacity:1;
        top:0;
    }
}
