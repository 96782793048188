#header {
  width:100%;
  position:fixed;
  padding:10px 30px;
  z-index:9999;
  bottom:0;
  border-top: 2px solid $white;
  border-bottom:6px solid $yellow;
  opacity:.9;
  white-space: nowrap;
  background: $dark_blue;
  // @include gradient-horizontal(#609acb, $dark_blue, 0%, 50%);


  @media (max-width:$desktop) {
  }

  @media (max-width:$tablet) {

  }

  @media (max-height:$small_screen_height) and (min-width:$tablet){
    padding:0 30px;
  }

  @media (max-width:$phone) {
    padding:10px 10px 5px 10px;
  }

  .share-actions {

    border: 2px solid black !important;
    position:absolute;
    bottom:100%;
    right:30px;
    background:white;
    border:solid 1px $text_color;

    .title {
        margin-bottom: 0;
    }

    ul {
      margin:0;
    }

    li {
      border-top:solid 1px $dark_gray;
      padding:0;

      &:first-child {
        border-top:none;
      }
    }

    a {
      padding:10px 50px 10px 30px;
      display:block;
      color:$text_color;
      font-weight: 400;
      font-size:1.4rem;
      letter-spacing: .1em;

      &:hover {
        background:$light_gray;
      }

      .sprite {
        margin-left:20px;
        display:block;
        width:25px;
        position:absolute;
        right:10px;
        top:10px;

        &.facebook {
          height:25px;
          width:20px;
          top:7px;
          @include site_sprite(0, 0);
        }

        &.twitter {
          height:25px;
          @include site_sprite(0, -34px);
        }

        &.google {
          height:25px;
          @include site_sprite(0, -75px);
        }

        &.email {
          height:25px;
          @include site_sprite(0, -100px);
        }
      }
    }
  }

    .footer-tagline {
        height: 50px !important;
        padding: 15px !important;
        font-size: 1.9rem;
    }


  .hover-animate {
    border-left:solid 1px rgba(255, 255, 255, .75);
    // margin-bottom:$margin;
    padding:15px;
    position:relative;
    cursor:pointer;
    display: inline-block;

    @include ra_transition();

    &:hover {
      background:rgba(255,255,255,.2);
      color: white;
    }

    .share-container {
      padding:10px 0;
      // margin-right:40px;
      cursor:pointer;
      font-size: 0;



      @media (max-width:$phone) {
        margin-right:0;
        display:inline-block;
        padding:0;

        svg {

        }

        .title {
          font-size: 10px;
          line-height: 1;
          display: block;
          margin-top: 10px;
        }
      }


    }

    &.share-trigger {
      //display: inline;
      padding: 11.5px 15px;
    }

    .title {
      margin:0;

      @media (max-width:$phone) {
        font-size:12px;
      }
    }

    .logo {
      @media (max-width:$tablet) {
        margin-top:0px;
      }
    }

    #share-icon {
      vertical-align: -7px;
      margin: 0 10px;

      @media (max-width:$phone) {
        width: 20px;
        height: 22px;
        display: block;
        margin: auto;
      }

      path {
        fill:white;
      }
    }

    .back-to-top {
      margin-right:20px;
      @media (max-width:$phone) {
        float:left;
      }
    }

    #arrow-icon {
      width:30px;
      height:30px;
      margin-right:10px;
      vertical-align:-7px;

      path {
        stroke:white;
        stroke-width:10;
      }
    }
  }

  @media (max-width: $phone_small) {
    .fixed-footer-container {
        display: flex;
        flex-direction: column;
        position: relative;

      .footer-logo {
        float: none;
        order: 1;
        border-bottom: 1px solid #fff;
        padding-bottom: 10px;
        margin-bottom: 10px;

        > img {
          max-width: 200px;
          margin: 0;
        }
      }

      .share {
        float: none;
        order: 2;
        position: static;

        .share-container {
          display: flex;
          flex-wrap: wrap;

          .footer-tagline {
            display: none;
          }
          .footer-link {
            width: 50%;
            padding: 5px 0 10px;
            font-size: 1.4rem;
            line-height: 1;
            border: none;
          }
          .share-trigger {
            position: absolute;
            top: -10px;
            right: 0;
            z-index: 2;
            border: none;
          }
        }
      }
    }
  }

  @media (min-width:980px) {
    .share-container {
      float: right !important;
    }
  }

  @media (min-width:980px) {
    .footer-logo img {
      float: left !important;
    }
  }


  @media (max-width:980px) {
    text-align: center;

    .footer-tagline,
    .footer-link {
      float: none !important;
    }

    .footer-logo img {
      margin: 10px auto 0;
    }


  }


}
