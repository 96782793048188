.video-thumb {
    position:relative;
    margin-bottom:$margin;

    @include ra_transition();

    svg {
        @include ra_transition();
    }

    &:hover {

        svg {
            transform:scale3d(1.2, 1.2, 1.2);
        }

    }

    @media (max-width:$phone) {
        margin:40px auto 0 auto;
        width:300px;
        max-width:100%;
    }

    img {
        width:100%;
    }
}

.mobile-iframe {
    display:none;
    margin:40px auto 0 auto;
    width:300px;
    height:175px;
    max-width:100%;

    @media (max-width:$phone) {
    }
}
