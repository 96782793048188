/*-----------------------------------------------
  Base SCSS - No styles actually output. Variables / Functions / Mixins
----------------------------------------------- */
// Bootstrap Core Variables
@import "../bootstrap/stylesheets/bootstrap/_variables.scss";

// Bootstrap Custom Variables
@import "../bootstrap/stylesheets/bootstrap/__custom-variables.scss";

// Site Variables
@import "base/_variables.scss";

// Base Framework Functions
@import "base/_functions.scss";

// Bootstrap Mixins
@import "../bootstrap/stylesheets/bootstrap/_mixins.scss";

// Site Mixins
@import "base/_mixins.scss";
