/* Typography Styles
** Define modular site text styles.
---------------------------------- */

//Font mixins
@mixin copy {
	font-size:$copy_font_size;
	font-family: $sans;
}

@mixin heading {
	font-family: $sans;
	font-weight:500;
}

@mixin h1 {
	font-size:3.6rem;
	@include heading;
}

@mixin h2 {
	font-size:3rem;
	@include heading;
}

@mixin h3 {
	font-size:2.4rem;
	@include heading;
}

@mixin h4 {
	font-size:1.8rem;
	@include heading;
}


html {
	font-size:$base_font_size;

	// calculate base line-height by the default copy size // 16px
	//@include getLineHeight($base_font_size * $copy_font_mult);

	@media (max-height:$small_screen_height) {
		font-size:$base_font_size*0.9;
	}

	@media (max-width:$tablet) {
		font-size:$base_font_size*0.8;
	}

	@media (max-width:$phone) {
		font-size:$base_font_size;
		text-align:center;
	}

}

body {
	font-size: 1.9rem;
	font-family: $sans;
	-webkit-backface-visibility:hidden;
	backface-visibility:hidden;
}

p, a, li, label {

	margin:0;
	@include copy;

	@media (max-width:$phone) {
		text-align:center;
	}

	a {
		font-size:inherit;
	}
}

a {
	@include ra_transition(color, 200ms, ease-in-out, 0ms);
	text-decoration: none !important;
	color:primary($color-list);
	font-family:$sans;
	&:hover, &:focus {
		text-decoration: none !important;
		color:secondary($color-list);
	}
}


p, ul, ol {
	margin-bottom:$margin;

	&:first-child {
		margin-top:0;
	}
}

ul {
	li {
		padding-left:$margin/2;
		position:relative;
	}
}

ol {
	list-style-position: inside;
	list-style-type:decimal;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $serif;
	margin:0;
	color:primary($color-list);

	@media (max-width:$phone) {
		text-align:center;
	}
}

h1, .rah1 {
	@include h1;
}

h2, .rah2 {
	@include h2;
}

h3, .rah3 {
	@include h3;

}

h4, .rah4 {
	@include h4;
}

h5, .rah5 {
	@include h4;
}

h6, .rah6 {
	@include h4;
}

.cta {
    font-weight: 700;
    text-transform: uppercase;
    border: $dark_blue 1px solid;
    padding: 10px 30px;
    color: $dark_blue;
    background: transparent;
    display: inline-block;

    &.yellow {
        border-color: $yellow;
        color: $yellow;
    }

    &:hover {
    	background: $dark_blue;
    	color: $white;

    	&.yellow {
    		background: transparent;

    	}
    }

}

.sans 	{
	@include copy;
}

.serif {
	font-family:$serif;
	letter-spacing:0.05em;
}

.copy {
	@extend .sans;
    font-size:1.8rem;
    font-weight:300;
    margin-bottom:$margin;

    &.white {
        color:$white;
    }

    &.blue {
        color:$light_blue;
    }
}

.title {
    @extend .sans;
    font-size:1.8rem;
    font-weight:300;
    text-transform:uppercase;
    line-height:1.3;
    letter-spacing:0.05em;
    margin-bottom:$margin;

    &.heavy {
        font-weight:600;
    }

    &.extra-large {
        font-size:2.9rem;
    }

    &.large {
        font-size:2.3rem;
    }

    &.small {
        font-size:1.4rem;
    }

    &.yellow {
        color:$yellow;
    }

    &.white {
        color:$white;
    }

    &.blue {
        color:$light_blue;
    }

    &.dark_blue {
        color:$dark_blue;
    }

    &.black {
        color:$black;
    }
}

.learn-more {
    color:$yellow;
    font-weight:300;

    &:hover {
        color:$white;

        svg {
            path {
                fill:$white;
                stroke:$white;
            }
        }
    }
    svg {
        width:.8em;
        height:.8em;
        margin-left:10px;
        path {
            stroke:$yellow;
            fill:$yellow;
            @include ra_transition();
        }
    }
}

p:empty {
	display: none;
}

strong {
    font-weight:500;
}

.text-left {
	text-align: left !important;
}

.text-center {
	text-align: center !important;
}

.text-right {
	text-align: right !important;
}

.text_hidden {
	text-indent:-9999em;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-lowercase {
    text-transform:lowercase;
}


.footer-tagline {
	font-weight:500;
	color: white;
	letter-spacing:0.05em;
	padding: 1.0em;
	float: left;
}
.footer-link {
	font-weight: 200;
	color: white;
	letter-spacing:0.05em;
	padding: 1.0em;
	float: left;
}
