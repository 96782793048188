#panel3 {
    background:url("../../images/bg-smartcast.jpg");
    background-position:center top;
    background-size:cover;

    header, .content .copy, .content .title, .video-carousel {
        opacity:0;
        @include ra_transition();
    }

    .copy, .title {
        margin:0 auto $margin*2 auto;
    }

    h1.title {
        margin-bottom:$margin;
    }

    .placeholder {
        visibility:hidden;
        margin-bottom:$margin;
        display:block;
    }

    $opening_timing: 1s;

    header {
        position:absolute;
        width:100%;
        padding:0 $margin/2;
        top:50%;
        left:0;
    }

    &.active {

        header {
            animation:panel-header $opening_timing forwards;
        }

        .content {

            .copy, .title, .video-carousel {
                position:relative;
                animation:panel1-content 1s forwards;
                animation-delay:$opening_timing - .5;
            }

            .title.white:not(.large) {
                animation-delay:$opening_timing - .2;
            }

            .video-carousel {
                animation-delay:$opening_timing + .1;
            }
        }
    }
}
