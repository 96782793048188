$white:				#ffffff;
$text: 	        	#2c2c2c;
$yellow:			#ffc000;
$yellow_hover:		#FFE626;
$light_blue:		#5480c3;
$light_blue_hover:	#7AA6E9;
$dark_blue:			#005596;
$dark_blue_hover:	#5480c3;
$light_gray:		#e2e2e2;
$light_gray_hover:	#bcbcbc;
$dark_gray:			#c9c9c9;
$black:             #000000;

$color-list: (
    "white":				$white,
    "text": 	        	$text,
    "yellow":			    $yellow,
    "yellow_hover":		    $yellow_hover,
    "light_blue":		    $light_blue,
    "light_blue_hover":	    $light_blue_hover,
    "dark_blue":			$dark_blue,
    "dark_blue_hover":	    $dark_blue_hover,
    "light_gray":		    $light_gray,
    "light_gray_hover":	    $light_gray_hover,
    "dark_gray":			$dark_gray,
    "black":                $black
);

$serif: serif;
$sans: 'Roboto', sans-serif;

// Fonts
$font-list: (
	"serif":	($serif),
	"sans":		($sans),
);

// Font Sizes
$base_font_size: 10px;
$copy_font_mult: 1.6;
$copy_font_size: $copy_font_mult + rem; // 16px

// Global variables
$border_radius:0;

$small_screen_height: 750px;

// Footer Heights
$footer_height: 76px;
