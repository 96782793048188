#bullet-nav {
    position:fixed;
    z-index:500;
    left:20px;
    top:50%;
    transform:translateY(-50%);

    @media (max-width:$phone) {
        display:none;
    }

    ul {
        margin:0;
    }

    li {
        padding:20px 0;

        &.dark {
            .bullet {
                background:$light_blue;
            }

            a {

                &:hover {
                    .bullet {
                        background:$light_blue;
                    }
                }

                &.active {
                    .bullet {
                        background:$yellow;
                    }
                }

            }

            .panel-link {
                color:$light_blue;
            }
        }
    }

    a {
        position:relative;
        display:block;

        &:hover {
            color:$white;

            .panel-link-wrapper {
                left:-20px;
                opacity:1;
            }

            .bullet {
                background:$white;
            }
        }

        &.active {
            .bullet {
                background:$yellow;
                box-shadow:0px 0px 18px 7px $white;
            }
        }

        &:focus {
            outline:none;
        }
    }

    .bullet {
        display:block;
        width:15px;
        height:15px;
        border-radius:50%;
        background:$light_gray;
        position:relative;
        z-index:550;

        @include ra_transition();
    }

    .panel-link-wrapper {
        width:410px;
        position:absolute;
        left:-380px;
        top:50%;
        opacity:0;
        z-index:500;

        @include ra_transition();
        transition-property:opacity, left;
        transition-delay:100ms, 0ms;
    }

    .panel-link {
        display:inline-block;
        padding:10px 20px 10px 50px;
        transform:translateY(-50%);
        background:rgba($light_gray, .85);
        line-height:1.3;
        text-transform:uppercase;
        font-size:1.4rem;
        color:$text;
    }
}
