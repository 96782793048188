#panel6 {
    background: $white;
    padding: 0 !important;
    // height:calc(50% + 145px);
    // height:calc(50vh + 145px);
    height: auto !important;

    .panel_header {
        background: #EBEBEB;
        padding: 25px 0;
        width: 100%;

        .title {
            font-weight: 300;
            margin-bottom: 0 !important;

            strong {
                font-weight: 700;
            }
        }
    }

    .seven_wrapper {
        font-size: 0;
        @include clearfix;
        display: table;
        //margin-bottom: 81px;

        .seven_left,
        .seven_right {
            display: inline-block;
            width:50%;
            width:50vw;
            // height:calc(50% + 145px);
            // height:calc(50vh + 145px);
            padding-left: 50px;
            padding-right: 50px;

            display: table-cell;
            padding-bottom: 100px;
        }

        img {
            margin: 0 auto;
        }

        .seven_left {
            background: $white;

           // float: left;

            img {
                padding-bottom: 35px;
            }
        }

        .seven_right {
            background: #125892;
            padding-top: 160px;
            //float: right;

            img {
                max-width: 735px;
                width: 100%;
            }
        }

    }

    @media (max-width:980px) {

        height:auto !important;

        .seven_wrapper {
            display: block;

            .seven_left,
            .seven_right {
                display: block;
                width: 100%;
                height: auto;
                padding: 50px;
                float: none;
            }
        }
    }

}

#main {
    padding-bottom: 81px;
    @media (max-width:980px) {
        padding-bottom: 139px;
    }

    @media (max-width:$phone_small) {
        padding-bottom: 100px;
    }
}
