/*
Theme Name: Cree - Light A Better Way
Author: Rivers Agency // Trevor Krumm
Author URI: http://riversagency.com/
Version: 1.0
*/


/* CSS Section Imports
----------------------------------------------- */
@charset "utf-8";


/*-----------------------------------------------
  Base SCSS - No styles actually output.
  Variables / Functions / Mixins
----------------------------------------------- */
@import "../assets/_base.scss";


/*-----------------------------------------------
  Framework SCSS
  Resets / Bootstrap / Typography / Functional CSS
----------------------------------------------- */
@import "../assets/_framework.scss";


/*-----------------------------------------------
  Start Custom Theme CSS Below
----------------------------------------------- */

// Components
@import "../assets/_components.scss";

#main {
    overflow:hidden;
    display:block;
}
#panel7 {
    padding:250px 0 250px 0;
    
    background-position:center center;
    background-size:cover;
    position:relative;

    .title {
        margin-bottom:60px;
    }
}
