.vignette {
    border:solid 1px rgba(255, 255, 255, .75);
    margin-bottom:$margin;
    padding:15px;
    position:relative;
    cursor:pointer;

    @include ra_transition();

    &:hover {
        background:rgba(255,255,255,.2);

        #icon-collaboration {
            #person1, #person2, #person3 {
                animation: icon-collaboration 1s backwards
            }

            #person2 {
                animation-delay: 300ms;
            }

            #person3 {
                animation-delay: 600ms;
            }
        }

        #icon-better-value {
            #arrow {
                animation: icon-better-value-arrow 1s;
            }
            #circle {
                animation: icon-better-value-circle 1s;
            }
            #dollar {
                animation: icon-better-value-dollar 1s;
            }
        }

        #icon-connected {
            #signal1 {
                animation: icon-connected-signal1 1.5s infinite;
            }

            #signal2 {
                animation: icon-connected-signal2 1.5s infinite;
            }

            #signal3 {
                animation: icon-connected-signal3 1.5s infinite;
            }
        }

        #icon-true-innovation {
            #gear1-rotate {
                animation:rotate infinite linear 3s;
            }
            #gear2-rotate {
                animation:rotate-reverse infinite linear 3s;
            }
        }

        #icon-better-light {
            #beam1 {
                animation: icon-better-light-beam1 2s infinite;
            }
            #beam2 {
                animation: icon-better-light-beam2 2s infinite;
            }
            #beam3 {
                animation: icon-better-light-beam3 2s infinite;
            }
            #beam4 {
                animation: icon-better-light-beam4 2s infinite;
            }
            #beam5 {
                animation: icon-better-light-beam5 2s infinite;
            }
            #beam6 {
                animation: icon-better-light-beam6 2s infinite;
            }
            #beam7 {
                animation: icon-better-light-beam7 2s infinite;
            }
        }

        #icon-big-data {
            #light1 {
                animation: icon-big-data-light1 2s infinite backwards;
            }

            #light2 {
                animation: icon-big-data-light2 2s infinite backwards;
            }
        }

        #icon-evolution {
            #stem1 {
                animation: icon-evolution-stem1 500ms backwards;
            }
            #stem2 {
                animation: icon-evolution-stem1 500ms backwards;
                animation-delay: 200ms;
            }
            #stem3 {
                animation: icon-evolution-stem3 800ms backwards;
                animation-delay: 400ms;
            }
            #leaf1side1, #leaf1side2, #leaf2side1, #leaf2side2 {
                animation: icon-evolution-leafside 500ms backwards;
                animation-delay: 700ms;
            }

            #leaf1vein1, #leaf1vein2, #leaf2vein1, #leaf2vein2 {
                animation: icon-evolution-vein 500ms backwards;
                animation-delay: 700ms;
            }

            #stem4 {
                animation: icon-evolution-stem3 800ms backwards;
                animation-delay: 1s;
            }

            #leaf2side1, #leaf2side2, #leaf2vein1, #leaf2vein2 {
                animation-delay:1.2s;
            }

            // #leaf2vein1, #leaf2vein2 {
            //     animation-delay:2s;
            // }

        }
    }

    .title {
        font-size:1.5rem;
        margin:0;
        text-align:center;
    }

    .vig-icon {
        height:60px;
        margin-bottom:10px;

        &.small {
            height:50px;
            margin-top:10px;
        }
    }

    .play-button-circle {
        width:24px;
        height:24px;
        vertical-align:-7px;
        margin-left:5px;

        .circle-bg {
            opacity:.2;
        }
    }
}

#icon-collaboration {
    fill:$white;
}

#icon-better-value {
    #arrow, #dollar {
        fill:$white;
        position:relative;
    }

    #circle {
        stroke:$white;
        stroke-dasharray:211;
    }
}

#icon-connected {
    fill:$white;
}

#icon-true-innovation {
    fill:$white;

    #gear1, #gear2 {
        transform-origin:50% 50%;

        .ff & {
            transform-origin:0 0;
        }
    }

    #gear1-ff-correct {
        transform:translate(46px, 82px);

        #gear1 {
            transform:translate(-46px, -82px);
        }
    }

    #gear2-ff-correct {
        transform:translate(94px, 34px);

        #gear2 {
            transform:translate(-94px, -34px);
        }
    }
}

#icon-better-light {
    fill:$white;
}

#icon-big-data {
    fill:$white;
}

#icon-evolution {
    stroke:$white;

    #stem1, #stem2 {
        stroke-dasharray:50;
    }

    #stem3, #stem4 {
        stroke-dasharray:165;
    }

    #leaf1side1, #leaf1side2, #leaf2side1, #leaf2side2 {
        stroke-dasharray:83;
    }

    #leaf1vein1, #leaf1vein2, #leaf2vein1, #leaf2vein2 {
        stroke-dasharray:59;
    }
}
