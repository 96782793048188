#panel4 {
    background:url("../../images/bg-innovation.jpg");
    background-position:left bottom;
    background-size:contain;
    background-repeat:no-repeat;

    @media (max-width:$phone) {
        background:$white;
    }

    .title, .video-thumb {
        @include ra_transition($duration:500ms);
        position:relative;
        opacity:0;
        transform:translateX(-50%);
    }

    header {

        .title {
            filter:blur(10px);
            transition-property:transform, filter, opacity;
            transition-delay:.5s, 0s, 0s;
        }
    }

    &.active {
        header {
            .title {
                opacity:1;
                filter:blur(0);
                transform:translateX(0);
            }
        }

        p.title {
            opacity:1;
            transform:translateX(0);
            transition-delay:.8s;


            &.blue {
                transition-delay:1.1s;
            }
        }

        .video-thumb {
            opacity:1;
            transform:translateX(0);
            transition-delay:1.5s;
        }
    }

    .video-thumb {
        width:500px;
        max-width:100%;

        @media (max-width:$desktop) {
            width:400px;
        }

        @media (max-width:$tablet) {
            width:300px;
        }
    }

    > .container {

        @media (max-width:$phone) {
            padding:0;
        }

        >.css_table > .css_table_cell {

            @media (max-width:$phone) {
                &:first-child {
                    display:table-footer-group;
                }

                &:last-child {
                    display:table-caption;
                }
            }
        }
    }
}
