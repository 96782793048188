.news-item {
    background:$white;
    padding:44px;
    padding-bottom:75px;
    text-align:left;
    min-height:441px;
    max-height:442px;

    @media (max-width:$phone) {
        padding:25px;
    }

    img {
        width:100%;
        margin-bottom:$margin;
    }

    .title {
        text-align:left;
    }

    .title.small {
        margin:0;
    }

    a {
        color:inherit;
        font-size:inherit;

        &:hover {
            color:$light_blue;
        }
    }

    .blue {
        a {
            &:hover {
                color:$black;
            }
        }
    }

    .read-more {
        position:absolute !important;
        bottom:44px !important;
        top:auto !important;
    }
}
