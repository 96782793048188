.carousel {
    position:relative;

    @media (max-width:$phone) {
        width:300px;
        max-width:100%;
        margin:auto;
        float:none;
    }

    .slide {
        margin:0 $margin;
        height: auto;
        @media (max-width:$phone) {
            margin:0 10px;
        }
    }

    .video-thumb {
        margin-bottom:0;
    }

    .arrow-prev, .arrow-next {
        position:absolute;
        top:50%;
        width:35px;
        height:58px;
        cursor:pointer;
        z-index:999;

        &:hover {
            path {
                stroke:$light_blue;

                @include ra_transition();
            }
        }
    }

    .arrow-prev {
        left:-50px;
        transform:rotateZ(-90deg) translateX(75%);

        @media (max-width:$phone) {
            left:-20px;
        }
    }

    .arrow-next {
        right:-50px;
        transform:rotateZ(90deg) translateX(-75%);

        @media (max-width:$phone) {
            right:-20px;
        }
    }

    &.vertical {

        .slide {
            margin: ($margin / 2) 0;
            @media (max-width:$phone) {
                margin:10px 0;
            }
        }

        .arrow-prev {
            left: -30px;
            transform: translateX(0) translateY(75%);
        }

        .arrow-next {
            right: -30px;
            transform: rotateZ(180deg) translateY(75%);
        }
    }
}

.news-carousel {

    @media (max-width:$desktop) {
        max-width:80%;
        margin:$margin*2 auto;
    }

    @media (max-width:$tablet) {
        max-width:400px;
    }

    .news-item {
        margin:0 $margin;

        @media (max-width:$phone) {
            margin:0 10px;
        }
    }

    .arrow-prev, .arrow-next {

        path {
            stroke:$light_blue;
        }

        &:hover {

            path {
                stroke:$black;
            }
        }
    }
}
