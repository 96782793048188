strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
a {
  cursor: pointer;
}
sup {
  font-size: .7em;
  vertical-align: top;
}
body {
  font-size: 10px;
  font-family: 'Open Sans', sans-serif;
}
html,
body {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  height: 100%;
  position: relative;
}
table * {
  vertical-align: top;
}
