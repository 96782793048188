#panel5 {
    padding-bottom:100px;

    @include ra_radial_gradient($center_color:lighten($yellow, 40%), $outer_color:$yellow);

    @media (max-height:$medium_screen_height) {
        height:auto;
        padding:120px 0;

        .container {
            transform:none;
            top:auto;
        }
    }

    .news-carousel {
        margin-top:$margin*4;

        @media (max-width:$phone) {
            margin-top:$margin;
        }

        .row {
            margin-left:-$margin;
            margin-right:-$margin;
        }

        [class*='col-'] {
            padding-left:$margin;
            padding-right:$margin;
        }
    }

    header {
        position:absolute;
        width:100%;
        top:50%;
        left:0;
        opacity:0;
        padding:0 $margin/2;

        @include ra_transition();
    }

    p.title, .vignettes {
        opacity:0;
        @include ra_transition();
    }

    .news-item {
        opacity:0;

        p.title {
            opacity:1;
        }
    }

    .placeholder {
        visibility:hidden;
        margin-bottom:$margin*2;
        display:block;
    }

    .slick-active, .arrow-prev, .arrow-next {
        opacity:0;
        @include ra_transition();

        @media (max-width:$phone) {
            opacity:1;
        }
    }

    .news-carousel {
        opacity:0;
        @include ra_transition($duration:10ms);
    }

    $opening_timing: 1s;

    &.active {
        header {
            animation:panel-header $opening_timing forwards;
        }

        .placeholder + p.title {
            position:relative;
            animation:panel1-content 1s forwards;
            animation-delay:$opening_timing - .5;
        }

        .slick-list {
            padding: 200px 0 30px 0;
            margin-top: -200px;
        }

        .slick-active, .news-item {
            opacity:1;
        }

        .arrow-prev, .arrow-next {
            opacity:1;
            transition-delay:$opening_timing - .2;
        }

        .news-carousel {
            opacity:1;
            transition-delay:$opening_timing - .5s;
        }
    }

    &.start {
        .slick-active {
            position:relative;
            animation:news-carousel 1s backwards;
            animation-delay:$opening_timing;

            + .slick-active {
                animation-delay: $opening_timing + .1;

                + .slick-active {
                    animation-delay: $opening_timing + .2;
                }
            }
        }
    }

}
