//
// Mixins
// --------------------------------------------------


// site sprites
// @param posx - x axis offset
// @param posy - y axis offset
// @param width - width of element
// @param height - height of element
// @param bgxpercent - percent x to shink sprite for responsive
// @param bgypercent - percent y to shink sprite for responsive
// @param constrain - if true converts original x,y offsets and original width/height to new sprite size
@mixin site_sprite($posx:0, $posy:0, $bgxpercent:100, $bgypercent:100, $constrain:false)
{


  $width:25px;
  $height:120px;

  background:url('../../images/social-sprite-retina.png') $posx $posy no-repeat;
  background-size: $width*($bgxpercent/100) $height*($bgypercent/100) !important;

  @if ($constrain == true) {
    background:url('../../images/social-sprite-retina.png') $posx*($bgxpercent/100) $posy*($bgypercent/100) no-repeat;
  }

  // Retina Sprites
  @media
    (min-moz-device-pixel-ratio: 2),
    (-o-min-device-pixel-ratio: 2/1),
    (-webkit-min-device-pixel-ratio: 2),
    (min-device-pixel-ratio: 2)
  {
    background-image:url('../../images/social-sprite-retina.png');
  }
}

@mixin ra_radial_gradient($shape:circle, $size:contain, $position:center center, $center_color:#63A2D3, $center_stop:0%, $center_transparency:1, $outer_color:#0457A5, $outer_stop:100%, $outer_transparency:1, $second_stop:20%) {


	$center_color_rgba: fade-in($center_color, $center_transparency);
	$outer_color_rgba: fade-in($outer_color, $outer_transparency);
	$second_color_rgba: fade-in(mix($center_color, $outer_color), 1);

	background: $center_color_rgba; /* Old browsers */
	background: -moz-radial-gradient($position, $shape $size,  $center_color_rgba $center_stop, $second_color_rgba $second_stop, $outer_color_rgba $outer_stop); /* FF3.6+ */
	background: -webkit-gradient(radial, $position, $center_stop, $position, $outer_stop, color-stop($center_stop,$center_color_rgba), color-stop($second_stop,$second_color_rgba), color-stop($outer_stop,$outer_color_rgba)); /* Chrome,Safari4+ */
	background: -webkit-radial-gradient($position, $shape $size,  $center_color_rgba $center_stop, $second_color_rgba $second_stop, $outer_color_rgba $outer_stop); /* Chrome10+,Safari5.1+ */
	background: -o-radial-gradient($position, $shape $size,  $center_color_rgba $center_stop, $second_color_rgba $second_stop, $outer_color_rgba $outer_stop); /* Opera 12+ */
	background: -ms-radial-gradient($position, $shape $size,  $center_color_rgba $center_stop, $second_color_rgba $second_stop, $outer_color_rgba $outer_stop); /* IE10+ */
	background: radial-gradient($shape at $position,  $center_color_rgba $center_stop, $second_color_rgba $second_stop, $outer_color_rgba $outer_stop); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$center_color', endColorstr='$outer_color',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}

///// Calculate ideal line height with the golden ratio

// 1.61803398874989 or "The Golden Ratio"
$phi: 1.61803398874989;

@mixin getLineHeight($font-size) {

	$grid-factor: 1/$grid-columns;

	.container {
		line-height: returnLineHeight($font-size, $lg-content-width);

		@media (max-width:$desktop) {
			line-height: returnLineHeight($font-size, $md-content-width);
		}

		@media (max-width:$tablet) {
			line-height: returnLineHeight($font-size, $sm-content-width);
		}

		@media (max-width:$phone_small) {
			line-height: returnLineHeight($font-size, $xs-content-width);
		}
	}

	@for $cols from 1 through $grid-columns {

		.col-xs-#{$cols} {
			$content-width: $grid-factor * $cols * $sm-content-width;
			line-height: returnLineHeight($font-size, $content-width);
		}
	}
	@for $cols from 1 through $grid-columns {

		@media (min-width:$screen-sm) {
			.col-sm-#{$cols} {
				$content-width: $grid-factor * $cols * $sm-content-width;
				line-height: returnLineHeight($font-size, $content-width);
			}
		}
	}
	@for $cols from 1 through $grid-columns {

		@media (min-width:$screen-md) {

			.col-md-#{$cols} {
				$content-width: $grid-factor * $cols * $md-content-width;
				line-height: returnLineHeight($font-size, $content-width);
			}
		}
	}
	@for $cols from 1 through $grid-columns {

		@media (min-width:$screen-lg) {

			.col-lg-#{$cols} {
				$content-width: $grid-factor * $cols * $lg-content-width;
				line-height: returnLineHeight($font-size, $content-width);
			}
		}
	}
}

@mixin glyphicon() {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin ra_transition($property:all, $duration:350ms, $timing:ease-out, $delay:0ms) {
  transition-property:$property;
  transition-duration:$duration;
  transition-timing-function:$timing;
  transition-delay:$delay;
}

@mixin smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin transform($content) {
  -moz-transform: $content;
  -o-transform: $content;
  -ms-transform: $content;
  -webkit-transform: $content;
  transform: $content;
}
