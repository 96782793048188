#pin-container {
    position:relative;
    // width:100%;
    // width:100vw;
    // height:calc(100% - #{$footer_height});
    // height:calc(100vh - #{$footer_height});
    //overflow:hidden;
}

.panel {
    position:relative;
    width:100%;
    width:100vw;
    height:calc(100% - #{$footer_height});
    height:calc(100vh - #{$footer_height});
    padding:60px 0;
    //top:0;

    &:last-child {
        height:100%;
        height:100vh;
    }

    @media (max-height:$small_screen_height) {
        padding:120px 0;
        height:auto;
    }

    @media (max-width:$phone) {
        height:auto;
        padding:60px 0;
    }

    > .container {
        position:relative;
        top:50%;
        transform:translateY(-50%);
        z-index:50;

        @media (max-height:$small_screen_height) {
            transform:none;
            top:auto;
        }

        @media (max-width:$phone) {
            top:auto;
            transform:none;
        }
    }

    header, .title, .video-thumb, .copy, .video-carousel, .vignettes, .slick-active {
        @media (max-width:$phone) {
            animation:none !important;
            opacity:1 !important;
            transform:none !important;
            filter:none !important;
            top:0 !important;
        }
    }

    .slick-active {
        @media (max-width:$phone) {
            opacity:1 !important;
            filter:none !important;
            top:0 !important;
        }
    }
}
